import { Box, VStack } from '@chakra-ui/react'
import { VFC } from 'react'
import { SideMenuItem, SideMenuItemProps } from './item.side-menu'

type DesktopSideMenuProps = {
  sideMenuItemPropsList: SideMenuItemProps[]
}

export const DesktopSideMenu: VFC<DesktopSideMenuProps> = ({
  sideMenuItemPropsList,
}) => {
  const sideMenuItems = sideMenuItemPropsList.map(
    (sideMenuItemProps, index) => (
      <Box w="100%" key={`side-menu-item-${index}`}>
        <SideMenuItem {...sideMenuItemProps} />
      </Box>
    )
  )
  return (
    <nav>
      <VStack alignItems="baseline">{sideMenuItems}</VStack>
    </nav>
  )
}
