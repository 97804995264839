import { TabLinkItemAtom } from '@/components/atoms/common/navigator/tab/tab-link-item.atoms'
import { TabContainerMolecule } from '@/components/molecules/common/navigator/tab/tab-container.molecules'
import { RoomMenuStatusType } from '@/stores/contexts/layout.context'
import { pagesPath } from '@/utils/$path'
import { LinkProps } from 'next/link'
import { VFC } from 'react'

type RoomHeadMenuProps = {
  status: RoomMenuStatusType
}

export const RoomHeadMenu: VFC<RoomHeadMenuProps> = ({ status }) => {
  const roomPages = pagesPath.rooms._roomId(status.variables.roomId)

  const tabLinkItems: {
    href: LinkProps['href']
    name: RoomMenuStatusType['activeTabName']
    label: string
  }[] = [
    { label: '部屋情報', name: 'detail', href: roomPages.$url() },
    { label: '部屋設備', name: 'equipment', href: roomPages.equipments.$url() },
    { label: '部屋写真', name: 'photo', href: roomPages.photos.$url() },
  ]

  return (
    <>
      <TabContainerMolecule>
        {tabLinkItems.map((item) => (
          <TabLinkItemAtom
            key={`room-head-menu--${item.name}`}
            href={item.href}
            isActive={status.activeTabName === item.name}
          >
            {item.label}
          </TabLinkItemAtom>
        ))}
      </TabContainerMolecule>
    </>
  )
}
