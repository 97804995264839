import { TabContainerMolecule } from '@/components/molecules/common/navigator/tab/tab-container.molecules'
import { BuildingMenuStatusType } from '@/stores/contexts/layout.context'
import { VFC } from 'react'
import { TabLinkItemAtom } from '@/components/atoms/common/navigator/tab/tab-link-item.atoms'
import { LinkProps } from 'next/link'
import { pagesPath } from '@/utils/$path'

type BuildingHeadMenuProps = {
  status: BuildingMenuStatusType
}

export const BuildingHeadMenu: VFC<BuildingHeadMenuProps> = ({ status }) => {
  const buildingPages = pagesPath.buildings._buildingId(
    status.variables.buildingId
  )

  const tabLinkItems: {
    href: LinkProps['href']
    name: BuildingMenuStatusType['activeTabName']
    label: string
  }[] = [
    { label: '建物情報', name: 'detail', href: buildingPages.$url() },
    {
      label: '駐車場',
      name: 'parking',
      href: buildingPages.parkings.$url(),
    },
    {
      label: '物件設備',
      name: 'equipment',
      href: buildingPages.equipments.$url(),
    },
    {
      label: '物件写真',
      name: 'photo',
      href: buildingPages.photos.$url(),
    },
  ]

  return (
    <>
      <TabContainerMolecule>
        {tabLinkItems.map((item) => (
          <TabLinkItemAtom
            key={`building-head-menu--${item.name}`}
            href={item.href}
            isActive={status.activeTabName === item.name}
          >
            {item.label}
          </TabLinkItemAtom>
        ))}
      </TabContainerMolecule>
    </>
  )
}
