import { HStack } from '@chakra-ui/react'
import { ReactNode, VFC } from 'react'

type TabContainerMoleculeProps = {
  children: ReactNode
}

export const TabContainerMolecule: VFC<TabContainerMoleculeProps> = ({
  children,
}) => {
  return (
    <HStack justifyContent="center" spacing="16px">
      {children}
    </HStack>
  )
}
