// import styles
import '../styles/globals.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/thumbs'
import 'swiper/css/mousewheel'

// import modules
import type { AppProps } from 'next/app'
import { Amplify } from 'aws-amplify'
import { AppProvider } from '@/providers/app.provider'
import Head from 'next/head'
import { useRouter } from 'next/router'

Amplify.configure({
  Auth: {
    region: process.env.NEXT_PUBLIC_AWS_COGNITO_REGION,
    userPoolId: process.env.NEXT_PUBLIC_AWS_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_AWS_COGNITO_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.NEXT_PUBLIC_AWS_COGNITO_DOMAIN,
      scope: ['openid'],
      redirectSignIn: process.env.NEXT_PUBLIC_AWS_COGNITO_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.NEXT_PUBLIC_AWS_COGNITO_REDIRECT_SIGN_OUT,
      responseType: 'code',
    },
  },
})

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  return (
    <AppProvider>
      <Head>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {router.isReady && <Component {...pageProps} />}
    </AppProvider>
  )
}

export default MyApp
