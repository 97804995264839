import { ButtonMenuItemProps } from '@/components/layouts/types/button.menu-item-props.type'
import { VFC } from 'react'
import { BottomMenuItemCore } from './core'

export type ButtonBottomMenuItemProps = ButtonMenuItemProps

export const ButtonBottomMenuItem: VFC<
  Omit<ButtonBottomMenuItemProps, 'type'>
> = ({ onClick, ...coreProps }) => {
  return (
    <button onClick={onClick}>
      <BottomMenuItemCore {...coreProps}></BottomMenuItemCore>
    </button>
  )
}
