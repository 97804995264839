import { HStack, StackProps } from '@chakra-ui/react'
import { ReactNode, VFC } from 'react'

type DesktopMainProps = StackProps & {
  children: ReactNode
}

export const DesktopMain: VFC<DesktopMainProps> = ({
  children,
  ...boxProps
}) => {
  return (
    <HStack
      {...boxProps}
      minH="100%"
      alignItems="flexStart"
      justifyContent="center"
    >
      <main>{children}</main>
    </HStack>
  )
}
