import { locales } from '@/statics/locales'
import { useRouter } from 'next/router'

export type Locale = 'ja'
export const useLocale = (options: { locale?: Locale } = {}) => {
  const { locale: _locale, defaultLocale } = useRouter()
  let locale: Locale

  if (!_locale && !options.locale) {
    locale = defaultLocale as Locale
  }

  if (!!options.locale) {
    locale = options.locale
  } else {
    locale = _locale as Locale
  }

  if (!locale || !Object.keys(locales).includes(locale)) {
    return { locale: 'ja' as 'ja', texts: locales.ja }
  }

  return { locale, texts: locales[locale] }
}
