import { createContext, useContext } from 'react'

export type BuildingMenuStatusType = {
  type: 'building'
  activeTabName: 'detail' | 'parking' | 'equipment' | 'photo' | 'information'
  variables: {
    buildingId: number
  }
}
export type RoomMenuStatusType = {
  type: 'room'
  activeTabName: 'detail' | 'equipment' | 'photo' | 'information'
  variables: {
    roomId: number
  }
}
export type MenuStatusType =
  | BuildingMenuStatusType
  | RoomMenuStatusType
  | undefined

type LayoutContextType = {
  menuStatus: MenuStatusType
  setMenuStatus: (menuStatus: MenuStatusType) => void
}

export const LayoutContext = createContext<LayoutContextType>({
  menuStatus: undefined,
  setMenuStatus: (menuStatus) => console.debug(menuStatus),
})

export const useLayoutContext = () => useContext(LayoutContext)
