import { appLocaleTexts } from './app'
import { authLocaleTexts } from './auth'
import { cleaningLocaleTexts } from './cleaning'
import { commonLocaleTexts } from './common'

export const ja = {
  app: appLocaleTexts,
  common: commonLocaleTexts,
  auth: authLocaleTexts,
  cleaning: cleaningLocaleTexts,
}
