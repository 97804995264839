import * as _Unicons from '@iconscout/react-unicons'
import { VFC } from 'react'

type UniconProps = {
  color?: string
  size?: number
}

export type UniconComponent = VFC<UniconProps>

export const Unicons: {
  Uil0Plus: UniconComponent
  Uil10Plus: UniconComponent
  Uil12Plus: UniconComponent
  Uil13Plus: UniconComponent
  Uil16Plus: UniconComponent
  Uil17Plus: UniconComponent
  Uil18Plus: UniconComponent
  Uil21Plus: UniconComponent
  Uil3Plus: UniconComponent
  Uil500px: UniconComponent
  Uil6Plus: UniconComponent
  UilAbacus: UniconComponent
  UilAccessibleIconAlt: UniconComponent
  UilAdjust: UniconComponent
  UilAdjustAlt: UniconComponent
  UilAdjustCircle: UniconComponent
  UilAdjustHalf: UniconComponent
  UilAdobe: UniconComponent
  UilAdobeAlt: UniconComponent
  UilAirplay: UniconComponent
  UilAlign: UniconComponent
  UilAlignAlt: UniconComponent
  UilAlignCenter: UniconComponent
  UilAlignCenterAlt: UniconComponent
  UilAlignCenterH: UniconComponent
  UilAlignCenterJustify: UniconComponent
  UilAlignCenterV: UniconComponent
  UilAlignJustify: UniconComponent
  UilAlignLeft: UniconComponent
  UilAlignLeftJustify: UniconComponent
  UilAlignLetterRight: UniconComponent
  UilAlignRight: UniconComponent
  UilAlignRightJustify: UniconComponent
  UilAmazon: UniconComponent
  UilAmbulance: UniconComponent
  UilAnalysis: UniconComponent
  UilAnalytics: UniconComponent
  UilAnchor: UniconComponent
  UilAndroid: UniconComponent
  UilAndroidAlt: UniconComponent
  UilAndroidPhoneSlash: UniconComponent
  UilAngleDoubleDown: UniconComponent
  UilAngleDoubleLeft: UniconComponent
  UilAngleDoubleRight: UniconComponent
  UilAngleDoubleUp: UniconComponent
  UilAngleDown: UniconComponent
  UilAngleLeft: UniconComponent
  UilAngleLeftB: UniconComponent
  UilAngleRight: UniconComponent
  UilAngleRightB: UniconComponent
  UilAngleUp: UniconComponent
  UilAngry: UniconComponent
  UilAnkh: UniconComponent
  UilAnnoyed: UniconComponent
  UilAnnoyedAlt: UniconComponent
  UilApple: UniconComponent
  UilAppleAlt: UniconComponent
  UilApps: UniconComponent
  UilArchive: UniconComponent
  UilArchiveAlt: UniconComponent
  UilArchway: UniconComponent
  UilArrow: UniconComponent
  UilArrowBreak: UniconComponent
  UilArrowCircleDown: UniconComponent
  UilArrowCircleLeft: UniconComponent
  UilArrowCircleRight: UniconComponent
  UilArrowCircleUp: UniconComponent
  UilArrowCompressH: UniconComponent
  UilArrowDown: UniconComponent
  UilArrowDownLeft: UniconComponent
  UilArrowDownRight: UniconComponent
  UilArrowFromRight: UniconComponent
  UilArrowFromTop: UniconComponent
  UilArrowGrowth: UniconComponent
  UilArrowLeft: UniconComponent
  UilArrowRandom: UniconComponent
  UilArrowResizeDiagonal: UniconComponent
  UilArrowRight: UniconComponent
  UilArrowToBottom: UniconComponent
  UilArrowToRight: UniconComponent
  UilArrowUp: UniconComponent
  UilArrowUpLeft: UniconComponent
  UilArrowUpRight: UniconComponent
  UilArrowsH: UniconComponent
  UilArrowsHAlt: UniconComponent
  UilArrowsLeftDown: UniconComponent
  UilArrowsMaximize: UniconComponent
  UilArrowsMerge: UniconComponent
  UilArrowsResize: UniconComponent
  UilArrowsResizeH: UniconComponent
  UilArrowsResizeV: UniconComponent
  UilArrowsRightDown: UniconComponent
  UilArrowsShrinkH: UniconComponent
  UilArrowsShrinkV: UniconComponent
  UilArrowsUpRight: UniconComponent
  UilArrowsV: UniconComponent
  UilArrowsVAlt: UniconComponent
  UilAssistiveListeningSystems: UniconComponent
  UilAsterisk: UniconComponent
  UilAt: UniconComponent
  UilAtom: UniconComponent
  UilAutoFlash: UniconComponent
  UilAward: UniconComponent
  UilAwardAlt: UniconComponent
  UilBabyCarriage: UniconComponent
  UilBackpack: UniconComponent
  UilBackspace: UniconComponent
  UilBackward: UniconComponent
  UilBag: UniconComponent
  UilBagAlt: UniconComponent
  UilBagSlash: UniconComponent
  UilBalanceScale: UniconComponent
  UilBan: UniconComponent
  UilBandAid: UniconComponent
  UilBars: UniconComponent
  UilBaseballBall: UniconComponent
  UilBasketball: UniconComponent
  UilBasketballHoop: UniconComponent
  UilBath: UniconComponent
  UilBatteryBolt: UniconComponent
  UilBatteryEmpty: UniconComponent
  UilBed: UniconComponent
  UilBedDouble: UniconComponent
  UilBehance: UniconComponent
  UilBehanceAlt: UniconComponent
  UilBell: UniconComponent
  UilBellSchool: UniconComponent
  UilBellSlash: UniconComponent
  UilBill: UniconComponent
  UilBing: UniconComponent
  UilBitcoin: UniconComponent
  UilBitcoinAlt: UniconComponent
  UilBitcoinCircle: UniconComponent
  UilBitcoinSign: UniconComponent
  UilBlackBerry: UniconComponent
  UilBlogger: UniconComponent
  UilBloggerAlt: UniconComponent
  UilBluetoothB: UniconComponent
  UilBold: UniconComponent
  UilBolt: UniconComponent
  UilBoltAlt: UniconComponent
  UilBoltSlash: UniconComponent
  UilBook: UniconComponent
  UilBookAlt: UniconComponent
  UilBookMedical: UniconComponent
  UilBookOpen: UniconComponent
  UilBookReader: UniconComponent
  UilBookmark: UniconComponent
  UilBookmarkFull: UniconComponent
  UilBooks: UniconComponent
  UilBoombox: UniconComponent
  UilBorderAlt: UniconComponent
  UilBorderBottom: UniconComponent
  UilBorderClear: UniconComponent
  UilBorderHorizontal: UniconComponent
  UilBorderInner: UniconComponent
  UilBorderLeft: UniconComponent
  UilBorderOut: UniconComponent
  UilBorderRight: UniconComponent
  UilBorderTop: UniconComponent
  UilBorderVertical: UniconComponent
  UilBowlingBall: UniconComponent
  UilBox: UniconComponent
  UilBracketsCurly: UniconComponent
  UilBrain: UniconComponent
  UilBriefcase: UniconComponent
  UilBriefcaseAlt: UniconComponent
  UilBright: UniconComponent
  UilBrightness: UniconComponent
  UilBrightnessEmpty: UniconComponent
  UilBrightnessHalf: UniconComponent
  UilBrightnessLow: UniconComponent
  UilBrightnessMinus: UniconComponent
  UilBrightnessPlus: UniconComponent
  UilBringBottom: UniconComponent
  UilBringFront: UniconComponent
  UilBrowser: UniconComponent
  UilBrushAlt: UniconComponent
  UilBug: UniconComponent
  UilBuilding: UniconComponent
  UilBullseye: UniconComponent
  UilBus: UniconComponent
  UilBusAlt: UniconComponent
  UilBusSchool: UniconComponent
  UilCalculator: UniconComponent
  UilCalculatorAlt: UniconComponent
  UilCalendarAlt: UniconComponent
  UilCalendarSlash: UniconComponent
  UilCalender: UniconComponent
  UilCalling: UniconComponent
  UilCamera: UniconComponent
  UilCameraChange: UniconComponent
  UilCameraPlus: UniconComponent
  UilCameraSlash: UniconComponent
  UilCancel: UniconComponent
  UilCapsule: UniconComponent
  UilCapture: UniconComponent
  UilCar: UniconComponent
  UilCarSideview: UniconComponent
  UilCarSlash: UniconComponent
  UilCarWash: UniconComponent
  UilCardAtm: UniconComponent
  UilCaretRight: UniconComponent
  UilCell: UniconComponent
  UilCelsius: UniconComponent
  UilChannel: UniconComponent
  UilChannelAdd: UniconComponent
  UilChart: UniconComponent
  UilChartBar: UniconComponent
  UilChartBarAlt: UniconComponent
  UilChartDown: UniconComponent
  UilChartGrowth: UniconComponent
  UilChartGrowthAlt: UniconComponent
  UilChartLine: UniconComponent
  UilChartPie: UniconComponent
  UilChartPieAlt: UniconComponent
  UilChat: UniconComponent
  UilChatBubbleUser: UniconComponent
  UilChatInfo: UniconComponent
  UilCheck: UniconComponent
  UilCheckCircle: UniconComponent
  UilCheckSquare: UniconComponent
  UilCircle: UniconComponent
  UilCircleLayer: UniconComponent
  UilCircuit: UniconComponent
  UilClapperBoard: UniconComponent
  UilClinicMedical: UniconComponent
  UilClipboard: UniconComponent
  UilClipboardAlt: UniconComponent
  UilClipboardBlank: UniconComponent
  UilClipboardNotes: UniconComponent
  UilClock: UniconComponent
  UilClockEight: UniconComponent
  UilClockFive: UniconComponent
  UilClockNine: UniconComponent
  UilClockSeven: UniconComponent
  UilClockTen: UniconComponent
  UilClockThree: UniconComponent
  UilClockTwo: UniconComponent
  UilClosedCaptioning: UniconComponent
  UilClosedCaptioningSlash: UniconComponent
  UilCloud: UniconComponent
  UilCloudBlock: UniconComponent
  UilCloudBookmark: UniconComponent
  UilCloudCheck: UniconComponent
  UilCloudComputing: UniconComponent
  UilCloudDataConnection: UniconComponent
  UilCloudDatabaseTree: UniconComponent
  UilCloudDownload: UniconComponent
  UilCloudDrizzle: UniconComponent
  UilCloudExclamation: UniconComponent
  UilCloudHail: UniconComponent
  UilCloudHeart: UniconComponent
  UilCloudInfo: UniconComponent
  UilCloudLock: UniconComponent
  UilCloudMeatball: UniconComponent
  UilCloudMoon: UniconComponent
  UilCloudMoonHail: UniconComponent
  UilCloudMoonMeatball: UniconComponent
  UilCloudMoonRain: UniconComponent
  UilCloudMoonShowers: UniconComponent
  UilCloudQuestion: UniconComponent
  UilCloudRain: UniconComponent
  UilCloudRainSun: UniconComponent
  UilCloudRedo: UniconComponent
  UilCloudShare: UniconComponent
  UilCloudShield: UniconComponent
  UilCloudShowers: UniconComponent
  UilCloudShowersAlt: UniconComponent
  UilCloudShowersHeavy: UniconComponent
  UilCloudSlash: UniconComponent
  UilCloudSun: UniconComponent
  UilCloudSunHail: UniconComponent
  UilCloudSunMeatball: UniconComponent
  UilCloudSunRain: UniconComponent
  UilCloudSunRainAlt: UniconComponent
  UilCloudSunTear: UniconComponent
  UilCloudTimes: UniconComponent
  UilCloudUnlock: UniconComponent
  UilCloudUpload: UniconComponent
  UilCloudWifi: UniconComponent
  UilCloudWind: UniconComponent
  UilClouds: UniconComponent
  UilClub: UniconComponent
  UilCodeBranch: UniconComponent
  UilCoffee: UniconComponent
  UilCog: UniconComponent
  UilCoins: UniconComponent
  UilColumns: UniconComponent
  UilComment: UniconComponent
  UilCommentAdd: UniconComponent
  UilCommentAlt: UniconComponent
  UilCommentAltBlock: UniconComponent
  UilCommentAltChartLines: UniconComponent
  UilCommentAltCheck: UniconComponent
  UilCommentAltDots: UniconComponent
  UilCommentAltDownload: UniconComponent
  UilCommentAltEdit: UniconComponent
  UilCommentAltExclamation: UniconComponent
  UilCommentAltHeart: UniconComponent
  UilCommentAltImage: UniconComponent
  UilCommentAltInfo: UniconComponent
  UilCommentAltLines: UniconComponent
  UilCommentAltLock: UniconComponent
  UilCommentAltMedical: UniconComponent
  UilCommentAltMessage: UniconComponent
  UilCommentAltNotes: UniconComponent
  UilCommentAltPlus: UniconComponent
  UilCommentAltQuestion: UniconComponent
  UilCommentAltRedo: UniconComponent
  UilCommentAltSearch: UniconComponent
  UilCommentAltShare: UniconComponent
  UilCommentAltShield: UniconComponent
  UilCommentAltSlash: UniconComponent
  UilCommentAltUpload: UniconComponent
  UilCommentAltVerify: UniconComponent
  UilCommentBlock: UniconComponent
  UilCommentChartLine: UniconComponent
  UilCommentCheck: UniconComponent
  UilCommentDots: UniconComponent
  UilCommentDownload: UniconComponent
  UilCommentEdit: UniconComponent
  UilCommentExclamation: UniconComponent
  UilCommentHeart: UniconComponent
  UilCommentImage: UniconComponent
  UilCommentInfo: UniconComponent
  UilCommentInfoAlt: UniconComponent
  UilCommentLines: UniconComponent
  UilCommentLock: UniconComponent
  UilCommentMedical: UniconComponent
  UilCommentMessage: UniconComponent
  UilCommentNotes: UniconComponent
  UilCommentPlus: UniconComponent
  UilCommentQuestion: UniconComponent
  UilCommentRedo: UniconComponent
  UilCommentSearch: UniconComponent
  UilCommentShare: UniconComponent
  UilCommentShield: UniconComponent
  UilCommentSlash: UniconComponent
  UilCommentUpload: UniconComponent
  UilCommentVerify: UniconComponent
  UilComments: UniconComponent
  UilCommentsAlt: UniconComponent
  UilCompactDisc: UniconComponent
  UilComparison: UniconComponent
  UilCompass: UniconComponent
  UilCompress: UniconComponent
  UilCompressAlt: UniconComponent
  UilCompressAltLeft: UniconComponent
  UilCompressArrows: UniconComponent
  UilCompressLines: UniconComponent
  UilCompressPoint: UniconComponent
  UilCompressV: UniconComponent
  UilConfused: UniconComponent
  UilConstructor: UniconComponent
  UilCopy: UniconComponent
  UilCopyAlt: UniconComponent
  UilCopyLandscape: UniconComponent
  UilCopyright: UniconComponent
  UilCornerDownLeft: UniconComponent
  UilCornerDownRight: UniconComponent
  UilCornerDownRightAlt: UniconComponent
  UilCornerLeftDown: UniconComponent
  UilCornerRightDown: UniconComponent
  UilCornerUpLeft: UniconComponent
  UilCornerUpLeftAlt: UniconComponent
  UilCornerUpRight: UniconComponent
  UilCornerUpRightAlt: UniconComponent
  UilCoronavirus: UniconComponent
  UilCreateDashboard: UniconComponent
  UilCreativeCommonsPd: UniconComponent
  UilCreditCard: UniconComponent
  UilCreditCardSearch: UniconComponent
  UilCrockery: UniconComponent
  UilCropAlt: UniconComponent
  UilCropAltRotateLeft: UniconComponent
  UilCropAltRotateRight: UniconComponent
  UilCrosshair: UniconComponent
  UilCrosshairAlt: UniconComponent
  UilCrosshairs: UniconComponent
  UilCss3Simple: UniconComponent
  UilCube: UniconComponent
  UilDashboard: UniconComponent
  UilDataSharing: UniconComponent
  UilDatabase: UniconComponent
  UilDatabaseAlt: UniconComponent
  UilDesert: UniconComponent
  UilDesktop: UniconComponent
  UilDesktopAlt: UniconComponent
  UilDesktopAltSlash: UniconComponent
  UilDesktopCloudAlt: UniconComponent
  UilDesktopSlash: UniconComponent
  UilDialpad: UniconComponent
  UilDialpadAlt: UniconComponent
  UilDiamond: UniconComponent
  UilDiary: UniconComponent
  UilDiaryAlt: UniconComponent
  UilDiceFive: UniconComponent
  UilDiceFour: UniconComponent
  UilDiceOne: UniconComponent
  UilDiceSix: UniconComponent
  UilDiceThree: UniconComponent
  UilDiceTwo: UniconComponent
  UilDirection: UniconComponent
  UilDirections: UniconComponent
  UilDiscord: UniconComponent
  UilDizzyMeh: UniconComponent
  UilDna: UniconComponent
  UilDocker: UniconComponent
  UilDocumentInfo: UniconComponent
  UilDocumentLayoutCenter: UniconComponent
  UilDocumentLayoutLeft: UniconComponent
  UilDocumentLayoutRight: UniconComponent
  UilDollarAlt: UniconComponent
  UilDollarSign: UniconComponent
  UilDollarSignAlt: UniconComponent
  UilDownloadAlt: UniconComponent
  UilDraggabledots: UniconComponent
  UilDribbble: UniconComponent
  UilDrill: UniconComponent
  UilDropbox: UniconComponent
  UilDumbbell: UniconComponent
  UilEar: UniconComponent
  UilEdit: UniconComponent
  UilEditAlt: UniconComponent
  UilElipsisDoubleVAlt: UniconComponent
  UilEllipsisH: UniconComponent
  UilEllipsisV: UniconComponent
  UilEmoji: UniconComponent
  UilEnglishToChinese: UniconComponent
  UilEnter: UniconComponent
  UilEnvelope: UniconComponent
  UilEnvelopeAdd: UniconComponent
  UilEnvelopeAlt: UniconComponent
  UilEnvelopeBlock: UniconComponent
  UilEnvelopeBookmark: UniconComponent
  UilEnvelopeCheck: UniconComponent
  UilEnvelopeDownload: UniconComponent
  UilEnvelopeDownloadAlt: UniconComponent
  UilEnvelopeEdit: UniconComponent
  UilEnvelopeExclamation: UniconComponent
  UilEnvelopeHeart: UniconComponent
  UilEnvelopeInfo: UniconComponent
  UilEnvelopeLock: UniconComponent
  UilEnvelopeMinus: UniconComponent
  UilEnvelopeOpen: UniconComponent
  UilEnvelopeQuestion: UniconComponent
  UilEnvelopeReceive: UniconComponent
  UilEnvelopeRedo: UniconComponent
  UilEnvelopeSearch: UniconComponent
  UilEnvelopeSend: UniconComponent
  UilEnvelopeShare: UniconComponent
  UilEnvelopeShield: UniconComponent
  UilEnvelopeStar: UniconComponent
  UilEnvelopeTimes: UniconComponent
  UilEnvelopeUpload: UniconComponent
  UilEnvelopeUploadAlt: UniconComponent
  UilEnvelopes: UniconComponent
  UilEqualCircle: UniconComponent
  UilEstate: UniconComponent
  UilEuro: UniconComponent
  UilEuroCircle: UniconComponent
  UilExchange: UniconComponent
  UilExchangeAlt: UniconComponent
  UilExclamation: UniconComponent
  UilExclamationCircle: UniconComponent
  UilExclamationOctagon: UniconComponent
  UilExclamationTriangle: UniconComponent
  UilExclude: UniconComponent
  UilExpandAlt: UniconComponent
  UilExpandArrows: UniconComponent
  UilExpandArrowsAlt: UniconComponent
  UilExpandFromCorner: UniconComponent
  UilExpandLeft: UniconComponent
  UilExpandRight: UniconComponent
  UilExport: UniconComponent
  UilExposureAlt: UniconComponent
  UilExposureIncrease: UniconComponent
  UilExternalLinkAlt: UniconComponent
  UilEye: UniconComponent
  UilEyeSlash: UniconComponent
  UilFacebook: UniconComponent
  UilFacebookF: UniconComponent
  UilFacebookMessenger: UniconComponent
  UilFacebookMessengerAlt: UniconComponent
  UilFahrenheit: UniconComponent
  UilFastMail: UniconComponent
  UilFastMailAlt: UniconComponent
  UilFavorite: UniconComponent
  UilFeedback: UniconComponent
  UilFidgetSpinner: UniconComponent
  UilFile: UniconComponent
  UilFileAlt: UniconComponent
  UilFileBlank: UniconComponent
  UilFileBlockAlt: UniconComponent
  UilFileBookmarkAlt: UniconComponent
  UilFileCheck: UniconComponent
  UilFileCheckAlt: UniconComponent
  UilFileContract: UniconComponent
  UilFileContractDollar: UniconComponent
  UilFileCopyAlt: UniconComponent
  UilFileDownload: UniconComponent
  UilFileDownloadAlt: UniconComponent
  UilFileEditAlt: UniconComponent
  UilFileExclamation: UniconComponent
  UilFileExclamationAlt: UniconComponent
  UilFileExport: UniconComponent
  UilFileGraph: UniconComponent
  UilFileHeart: UniconComponent
  UilFileImport: UniconComponent
  UilFileInfoAlt: UniconComponent
  UilFileLandscape: UniconComponent
  UilFileLandscapeAlt: UniconComponent
  UilFileLanscapeSlash: UniconComponent
  UilFileLockAlt: UniconComponent
  UilFileMedical: UniconComponent
  UilFileMedicalAlt: UniconComponent
  UilFileMinus: UniconComponent
  UilFileMinusAlt: UniconComponent
  UilFileNetwork: UniconComponent
  UilFilePlus: UniconComponent
  UilFilePlusAlt: UniconComponent
  UilFileQuestion: UniconComponent
  UilFileQuestionAlt: UniconComponent
  UilFileRedoAlt: UniconComponent
  UilFileSearchAlt: UniconComponent
  UilFileShareAlt: UniconComponent
  UilFileShieldAlt: UniconComponent
  UilFileSlash: UniconComponent
  UilFileTimes: UniconComponent
  UilFileTimesAlt: UniconComponent
  UilFileUpload: UniconComponent
  UilFileUploadAlt: UniconComponent
  UilFilesLandscapes: UniconComponent
  UilFilesLandscapesAlt: UniconComponent
  UilFilm: UniconComponent
  UilFilter: UniconComponent
  UilFilterSlash: UniconComponent
  UilFire: UniconComponent
  UilFlask: UniconComponent
  UilFlaskPotion: UniconComponent
  UilFlipH: UniconComponent
  UilFlipHAlt: UniconComponent
  UilFlipV: UniconComponent
  UilFlipVAlt: UniconComponent
  UilFlower: UniconComponent
  UilFocus: UniconComponent
  UilFocusAdd: UniconComponent
  UilFocusTarget: UniconComponent
  UilFolder: UniconComponent
  UilFolderCheck: UniconComponent
  UilFolderDownload: UniconComponent
  UilFolderExclamation: UniconComponent
  UilFolderHeart: UniconComponent
  UilFolderInfo: UniconComponent
  UilFolderLock: UniconComponent
  UilFolderMedical: UniconComponent
  UilFolderMinus: UniconComponent
  UilFolderNetwork: UniconComponent
  UilFolderOpen: UniconComponent
  UilFolderPlus: UniconComponent
  UilFolderQuestion: UniconComponent
  UilFolderSlash: UniconComponent
  UilFolderTimes: UniconComponent
  UilFolderUpload: UniconComponent
  UilFont: UniconComponent
  UilFootball: UniconComponent
  UilFootballAmerican: UniconComponent
  UilFootballBall: UniconComponent
  UilForecastcloudMoonTear: UniconComponent
  UilForwadedCall: UniconComponent
  UilForward: UniconComponent
  UilFrown: UniconComponent
  UilGameStructure: UniconComponent
  UilGift: UniconComponent
  UilGithub: UniconComponent
  UilGithubAlt: UniconComponent
  UilGitlab: UniconComponent
  UilGlass: UniconComponent
  UilGlassMartini: UniconComponent
  UilGlassMartiniAlt: UniconComponent
  UilGlassMartiniAltSlash: UniconComponent
  UilGlassTea: UniconComponent
  UilGlobe: UniconComponent
  UilGold: UniconComponent
  UilGolfBall: UniconComponent
  UilGoogle: UniconComponent
  UilGoogleDrive: UniconComponent
  UilGoogleDriveAlt: UniconComponent
  UilGoogleHangouts: UniconComponent
  UilGoogleHangoutsAlt: UniconComponent
  UilGooglePlay: UniconComponent
  UilGraduationCap: UniconComponent
  UilGraphBar: UniconComponent
  UilGrid: UniconComponent
  UilGrids: UniconComponent
  UilGrin: UniconComponent
  UilGrinTongueWink: UniconComponent
  UilGrinTongueWinkAlt: UniconComponent
  UilGripHorizontalLine: UniconComponent
  UilHardHat: UniconComponent
  UilHdd: UniconComponent
  UilHeadSide: UniconComponent
  UilHeadSideCough: UniconComponent
  UilHeadSideMask: UniconComponent
  UilHeadphoneSlash: UniconComponent
  UilHeadphones: UniconComponent
  UilHeadphonesAlt: UniconComponent
  UilHeart: UniconComponent
  UilHeartAlt: UniconComponent
  UilHeartBreak: UniconComponent
  UilHeartMedical: UniconComponent
  UilHeartRate: UniconComponent
  UilHeartSign: UniconComponent
  UilHeartbeat: UniconComponent
  UilHindiToChinese: UniconComponent
  UilHipchat: UniconComponent
  UilHistory: UniconComponent
  UilHistoryAlt: UniconComponent
  UilHome: UniconComponent
  UilHorizontalAlignCenter: UniconComponent
  UilHorizontalAlignLeft: UniconComponent
  UilHorizontalAlignRight: UniconComponent
  UilHorizontalDistributionCenter: UniconComponent
  UilHorizontalDistributionLeft: UniconComponent
  UilHorizontalDistributionRight: UniconComponent
  UilHospital: UniconComponent
  UilHospitalSquareSign: UniconComponent
  UilHospitalSymbol: UniconComponent
  UilHourglass: UniconComponent
  UilHouseUser: UniconComponent
  UilHtml3: UniconComponent
  UilHtml3Alt: UniconComponent
  UilHtml5: UniconComponent
  UilHtml5Alt: UniconComponent
  UilHunting: UniconComponent
  UilIcons: UniconComponent
  UilIllustration: UniconComponent
  UilImage: UniconComponent
  UilImageAltSlash: UniconComponent
  UilImageBlock: UniconComponent
  UilImageBroken: UniconComponent
  UilImageCheck: UniconComponent
  UilImageDownload: UniconComponent
  UilImageEdit: UniconComponent
  UilImageLock: UniconComponent
  UilImageMinus: UniconComponent
  UilImagePlus: UniconComponent
  UilImageQuestion: UniconComponent
  UilImageRedo: UniconComponent
  UilImageResizeLandscape: UniconComponent
  UilImageResizeSquare: UniconComponent
  UilImageSearch: UniconComponent
  UilImageShare: UniconComponent
  UilImageShield: UniconComponent
  UilImageSlash: UniconComponent
  UilImageTimes: UniconComponent
  UilImageUpload: UniconComponent
  UilImageV: UniconComponent
  UilImages: UniconComponent
  UilImport: UniconComponent
  UilInbox: UniconComponent
  UilIncomingCall: UniconComponent
  UilInfo: UniconComponent
  UilInfoCircle: UniconComponent
  UilInstagram: UniconComponent
  UilInstagramAlt: UniconComponent
  UilIntercom: UniconComponent
  UilIntercomAlt: UniconComponent
  UilInvoice: UniconComponent
  UilItalic: UniconComponent
  UilJackhammer: UniconComponent
  UilJavaScript: UniconComponent
  UilKayak: UniconComponent
  UilKeySkeleton: UniconComponent
  UilKeySkeletonAlt: UniconComponent
  UilKeyboard: UniconComponent
  UilKeyboardAlt: UniconComponent
  UilKeyboardHide: UniconComponent
  UilKeyboardShow: UniconComponent
  UilKeyholeCircle: UniconComponent
  UilKeyholeSquare: UniconComponent
  UilKeyholeSquareFull: UniconComponent
  UilKid: UniconComponent
  UilLabel: UniconComponent
  UilLabelAlt: UniconComponent
  UilLamp: UniconComponent
  UilLanguage: UniconComponent
  UilLaptop: UniconComponent
  UilLaptopCloud: UniconComponent
  UilLaptopConnection: UniconComponent
  UilLaughing: UniconComponent
  UilLayerGroup: UniconComponent
  UilLayerGroupSlash: UniconComponent
  UilLayers: UniconComponent
  UilLayersAlt: UniconComponent
  UilLayersSlash: UniconComponent
  UilLeftArrowFromLeft: UniconComponent
  UilLeftArrowToLeft: UniconComponent
  UilLeftIndent: UniconComponent
  UilLeftIndentAlt: UniconComponent
  UilLeftToRightTextDirection: UniconComponent
  UilLetterChineseA: UniconComponent
  UilLetterEnglishA: UniconComponent
  UilLetterHindiA: UniconComponent
  UilLetterJapaneseA: UniconComponent
  UilLifeRing: UniconComponent
  UilLightbulb: UniconComponent
  UilLightbulbAlt: UniconComponent
  UilLine: UniconComponent
  UilLineAlt: UniconComponent
  UilLineSpacing: UniconComponent
  UilLink: UniconComponent
  UilLinkAdd: UniconComponent
  UilLinkAlt: UniconComponent
  UilLinkBroken: UniconComponent
  UilLinkH: UniconComponent
  UilLinkedin: UniconComponent
  UilLinkedinAlt: UniconComponent
  UilLinux: UniconComponent
  UilLiraSign: UniconComponent
  UilListOl: UniconComponent
  UilListOlAlt: UniconComponent
  UilListUiAlt: UniconComponent
  UilListUl: UniconComponent
  UilLocationArrow: UniconComponent
  UilLocationArrowAlt: UniconComponent
  UilLocationPinAlt: UniconComponent
  UilLocationPoint: UniconComponent
  UilLock: UniconComponent
  UilLockAccess: UniconComponent
  UilLockAlt: UniconComponent
  UilLockOpenAlt: UniconComponent
  UilLockSlash: UniconComponent
  UilLottiefiles: UniconComponent
  UilLottiefilesAlt: UniconComponent
  UilLuggageCart: UniconComponent
  UilMailbox: UniconComponent
  UilMailboxAlt: UniconComponent
  UilMap: UniconComponent
  UilMapMarker: UniconComponent
  UilMapMarkerAlt: UniconComponent
  UilMapMarkerEdit: UniconComponent
  UilMapMarkerInfo: UniconComponent
  UilMapMarkerMinus: UniconComponent
  UilMapMarkerPlus: UniconComponent
  UilMapMarkerQuestion: UniconComponent
  UilMapMarkerShield: UniconComponent
  UilMapMarkerSlash: UniconComponent
  UilMapPin: UniconComponent
  UilMapPinAlt: UniconComponent
  UilMars: UniconComponent
  UilMasterCard: UniconComponent
  UilMaximizeLeft: UniconComponent
  UilMedal: UniconComponent
  UilMedicalDrip: UniconComponent
  UilMedicalSquare: UniconComponent
  UilMedicalSquareFull: UniconComponent
  UilMediumM: UniconComponent
  UilMedkit: UniconComponent
  UilMeetingBoard: UniconComponent
  UilMegaphone: UniconComponent
  UilMeh: UniconComponent
  UilMehAlt: UniconComponent
  UilMehClosedEye: UniconComponent
  UilMessage: UniconComponent
  UilMetro: UniconComponent
  UilMicrophone: UniconComponent
  UilMicrophoneSlash: UniconComponent
  UilMicroscope: UniconComponent
  UilMicrosoft: UniconComponent
  UilMinus: UniconComponent
  UilMinusCircle: UniconComponent
  UilMinusPath: UniconComponent
  UilMinusSquare: UniconComponent
  UilMinusSquareFull: UniconComponent
  UilMissedCall: UniconComponent
  UilMobileAndroid: UniconComponent
  UilMobileAndroidAlt: UniconComponent
  UilMobileVibrate: UniconComponent
  UilModem: UniconComponent
  UilMoneyBill: UniconComponent
  UilMoneyBillSlash: UniconComponent
  UilMoneyBillStack: UniconComponent
  UilMoneyInsert: UniconComponent
  UilMoneyStack: UniconComponent
  UilMoneyWithdraw: UniconComponent
  UilMoneyWithdrawal: UniconComponent
  UilMoneybag: UniconComponent
  UilMoneybagAlt: UniconComponent
  UilMonitor: UniconComponent
  UilMonitorHeartRate: UniconComponent
  UilMoon: UniconComponent
  UilMoonEclipse: UniconComponent
  UilMoonset: UniconComponent
  UilMountains: UniconComponent
  UilMountainsSun: UniconComponent
  UilMouse: UniconComponent
  UilMouseAlt: UniconComponent
  UilMouseAlt2: UniconComponent
  UilMultiply: UniconComponent
  UilMusic: UniconComponent
  UilMusicNote: UniconComponent
  UilMusicTuneSlash: UniconComponent
  UilNA: UniconComponent
  UilNavigator: UniconComponent
  UilNerd: UniconComponent
  UilNewspaper: UniconComponent
  UilNinja: UniconComponent
  UilNoEntry: UniconComponent
  UilNotebooks: UniconComponent
  UilNotes: UniconComponent
  UilObjectGroup: UniconComponent
  UilObjectUngroup: UniconComponent
  UilOctagon: UniconComponent
  UilOkta: UniconComponent
  UilOpera: UniconComponent
  UilOperaAlt: UniconComponent
  UilOutgoingCall: UniconComponent
  UilPackage: UniconComponent
  UilPadlock: UniconComponent
  UilPagelines: UniconComponent
  UilPagerduty: UniconComponent
  UilPaintTool: UniconComponent
  UilPalette: UniconComponent
  UilPanelAdd: UniconComponent
  UilPanoramaH: UniconComponent
  UilPanoramaHAlt: UniconComponent
  UilPanoramaV: UniconComponent
  UilPaperclip: UniconComponent
  UilParagraph: UniconComponent
  UilParcel: UniconComponent
  UilParkingCircle: UniconComponent
  UilParkingSquare: UniconComponent
  UilPathfinder: UniconComponent
  UilPathfinderUnite: UniconComponent
  UilPause: UniconComponent
  UilPauseCircle: UniconComponent
  UilPaypal: UniconComponent
  UilPen: UniconComponent
  UilPentagon: UniconComponent
  UilPercentage: UniconComponent
  UilPhone: UniconComponent
  UilPhoneAlt: UniconComponent
  UilPhonePause: UniconComponent
  UilPhoneSlash: UniconComponent
  UilPhoneTimes: UniconComponent
  UilPhoneVolume: UniconComponent
  UilPicture: UniconComponent
  UilPizzaSlice: UniconComponent
  UilPlane: UniconComponent
  UilPlaneArrival: UniconComponent
  UilPlaneDeparture: UniconComponent
  UilPlaneFly: UniconComponent
  UilPlay: UniconComponent
  UilPlayCircle: UniconComponent
  UilPlug: UniconComponent
  UilPlus: UniconComponent
  UilPlusCircle: UniconComponent
  UilPlusSquare: UniconComponent
  UilPodium: UniconComponent
  UilPolygon: UniconComponent
  UilPostStamp: UniconComponent
  UilPostcard: UniconComponent
  UilPound: UniconComponent
  UilPoundCircle: UniconComponent
  UilPower: UniconComponent
  UilPrescriptionBottle: UniconComponent
  UilPresentation: UniconComponent
  UilPresentationCheck: UniconComponent
  UilPresentationEdit: UniconComponent
  UilPresentationLine: UniconComponent
  UilPresentationLinesAlt: UniconComponent
  UilPresentationMinus: UniconComponent
  UilPresentationPlay: UniconComponent
  UilPresentationPlus: UniconComponent
  UilPresentationTimes: UniconComponent
  UilPrevious: UniconComponent
  UilPricetagAlt: UniconComponent
  UilPrint: UniconComponent
  UilPrintSlash: UniconComponent
  UilProcess: UniconComponent
  UilProcessor: UniconComponent
  UilProgrammingLanguage: UniconComponent
  UilPump: UniconComponent
  UilPuzzlePiece: UniconComponent
  UilQrcodeScan: UniconComponent
  UilQuestion: UniconComponent
  UilQuestionCircle: UniconComponent
  UilRainbow: UniconComponent
  UilRaindrops: UniconComponent
  UilRaindropsAlt: UniconComponent
  UilReact: UniconComponent
  UilReceipt: UniconComponent
  UilReceiptAlt: UniconComponent
  UilRecordAudio: UniconComponent
  UilRedditAlienAlt: UniconComponent
  UilRedo: UniconComponent
  UilRefresh: UniconComponent
  UilRegistered: UniconComponent
  UilRepeat: UniconComponent
  UilRestaurant: UniconComponent
  UilRightIndentAlt: UniconComponent
  UilRightToLeftTextDirection: UniconComponent
  UilRobot: UniconComponent
  UilRocket: UniconComponent
  UilRopeWay: UniconComponent
  UilRotate360: UniconComponent
  UilRss: UniconComponent
  UilRssAlt: UniconComponent
  UilRssInterface: UniconComponent
  UilRuler: UniconComponent
  UilRulerCombined: UniconComponent
  UilRupeeSign: UniconComponent
  UilSad: UniconComponent
  UilSadCry: UniconComponent
  UilSadCrying: UniconComponent
  UilSadDizzy: UniconComponent
  UilSadSquint: UniconComponent
  UilSanitizer: UniconComponent
  UilSanitizerAlt: UniconComponent
  UilSave: UniconComponent
  UilScalingLeft: UniconComponent
  UilScalingRight: UniconComponent
  UilScenery: UniconComponent
  UilSchedule: UniconComponent
  UilScrew: UniconComponent
  UilScroll: UniconComponent
  UilScrollH: UniconComponent
  UilSearch: UniconComponent
  UilSearchAlt: UniconComponent
  UilSearchMinus: UniconComponent
  UilSearchPlus: UniconComponent
  UilSelfie: UniconComponent
  UilServer: UniconComponent
  UilServerAlt: UniconComponent
  UilServerConnection: UniconComponent
  UilServerNetwork: UniconComponent
  UilServerNetworkAlt: UniconComponent
  UilServers: UniconComponent
  UilServicemark: UniconComponent
  UilSetting: UniconComponent
  UilShare: UniconComponent
  UilShareAlt: UniconComponent
  UilShield: UniconComponent
  UilShieldCheck: UniconComponent
  UilShieldExclamation: UniconComponent
  UilShieldPlus: UniconComponent
  UilShieldQuestion: UniconComponent
  UilShieldSlash: UniconComponent
  UilShip: UniconComponent
  UilShop: UniconComponent
  UilShoppingBag: UniconComponent
  UilShoppingBasket: UniconComponent
  UilShoppingCart: UniconComponent
  UilShoppingCartAlt: UniconComponent
  UilShovel: UniconComponent
  UilShrink: UniconComponent
  UilShuffle: UniconComponent
  UilShutter: UniconComponent
  UilShutterAlt: UniconComponent
  UilSick: UniconComponent
  UilSigma: UniconComponent
  UilSignAlt: UniconComponent
  UilSignInAlt: UniconComponent
  UilSignLeft: UniconComponent
  UilSignOutAlt: UniconComponent
  UilSignRight: UniconComponent
  UilSignal: UniconComponent
  UilSignalAlt: UniconComponent
  UilSignalAlt3: UniconComponent
  UilSignin: UniconComponent
  UilSignout: UniconComponent
  UilSilence: UniconComponent
  UilSilentSquint: UniconComponent
  UilSimCard: UniconComponent
  UilSitemap: UniconComponent
  UilSkipForward: UniconComponent
  UilSkipForwardAlt: UniconComponent
  UilSkipForwardCircle: UniconComponent
  UilSkype: UniconComponent
  UilSkypeAlt: UniconComponent
  UilSlack: UniconComponent
  UilSlackAlt: UniconComponent
  UilSliderH: UniconComponent
  UilSliderHRange: UniconComponent
  UilSlidersV: UniconComponent
  UilSlidersVAlt: UniconComponent
  UilSmile: UniconComponent
  UilSmileBeam: UniconComponent
  UilSmileDizzy: UniconComponent
  UilSmileSquintWink: UniconComponent
  UilSmileSquintWinkAlt: UniconComponent
  UilSmileWink: UniconComponent
  UilSmileWinkAlt: UniconComponent
  UilSnapchatAlt: UniconComponent
  UilSnapchatGhost: UniconComponent
  UilSnapchatSquare: UniconComponent
  UilSnowFlake: UniconComponent
  UilSnowflake: UniconComponent
  UilSnowflakeAlt: UniconComponent
  UilSocialDistancing: UniconComponent
  UilSort: UniconComponent
  UilSortAmountDown: UniconComponent
  UilSortAmountUp: UniconComponent
  UilSorting: UniconComponent
  UilSpaceKey: UniconComponent
  UilSpade: UniconComponent
  UilSperms: UniconComponent
  UilSpin: UniconComponent
  UilSpinner: UniconComponent
  UilSpinnerAlt: UniconComponent
  UilSquare: UniconComponent
  UilSquareFull: UniconComponent
  UilSquareShape: UniconComponent
  UilSquint: UniconComponent
  UilStar: UniconComponent
  UilStarHalfAlt: UniconComponent
  UilStepBackward: UniconComponent
  UilStepBackwardAlt: UniconComponent
  UilStepBackwardCircle: UniconComponent
  UilStepForward: UniconComponent
  UilStethoscope: UniconComponent
  UilStethoscopeAlt: UniconComponent
  UilStopCircle: UniconComponent
  UilStopwatch: UniconComponent
  UilStopwatchSlash: UniconComponent
  UilStore: UniconComponent
  UilStoreAlt: UniconComponent
  UilStoreSlash: UniconComponent
  UilStreering: UniconComponent
  UilStretcher: UniconComponent
  UilSubject: UniconComponent
  UilSubway: UniconComponent
  UilSubwayAlt: UniconComponent
  UilSuitcase: UniconComponent
  UilSuitcaseAlt: UniconComponent
  UilSun: UniconComponent
  UilSunset: UniconComponent
  UilSurprise: UniconComponent
  UilSwatchbook: UniconComponent
  UilSwiggy: UniconComponent
  UilSwimmer: UniconComponent
  UilSync: UniconComponent
  UilSyncExclamation: UniconComponent
  UilSyncSlash: UniconComponent
  UilSyringe: UniconComponent
  UilTable: UniconComponent
  UilTableTennis: UniconComponent
  UilTablet: UniconComponent
  UilTablets: UniconComponent
  UilTachometerFast: UniconComponent
  UilTachometerFastAlt: UniconComponent
  UilTag: UniconComponent
  UilTagAlt: UniconComponent
  UilTape: UniconComponent
  UilTaxi: UniconComponent
  UilTear: UniconComponent
  UilTelegram: UniconComponent
  UilTelegramAlt: UniconComponent
  UilTelescope: UniconComponent
  UilTemperature: UniconComponent
  UilTemperatureEmpty: UniconComponent
  UilTemperatureHalf: UniconComponent
  UilTemperatureMinus: UniconComponent
  UilTemperaturePlus: UniconComponent
  UilTemperatureQuarter: UniconComponent
  UilTemperatureThreeQuarter: UniconComponent
  UilTennisBall: UniconComponent
  UilText: UniconComponent
  UilTextFields: UniconComponent
  UilTextSize: UniconComponent
  UilTextStrikeThrough: UniconComponent
  UilTh: UniconComponent
  UilThLarge: UniconComponent
  UilThSlash: UniconComponent
  UilThermometer: UniconComponent
  UilThumbsDown: UniconComponent
  UilThumbsUp: UniconComponent
  UilThunderstorm: UniconComponent
  UilThunderstormMoon: UniconComponent
  UilThunderstormSun: UniconComponent
  UilTicket: UniconComponent
  UilTimes: UniconComponent
  UilTimesCircle: UniconComponent
  UilTimesSquare: UniconComponent
  UilToggleOff: UniconComponent
  UilToggleOn: UniconComponent
  UilToiletPaper: UniconComponent
  UilTopArrowFromTop: UniconComponent
  UilTopArrowToTop: UniconComponent
  UilTornado: UniconComponent
  UilTrademark: UniconComponent
  UilTrademarkCircle: UniconComponent
  UilTrafficBarrier: UniconComponent
  UilTrafficLight: UniconComponent
  UilTransaction: UniconComponent
  UilTrash: UniconComponent
  UilTrashAlt: UniconComponent
  UilTrees: UniconComponent
  UilTriangle: UniconComponent
  UilTrophy: UniconComponent
  UilTrowel: UniconComponent
  UilTruck: UniconComponent
  UilTruckLoading: UniconComponent
  UilTumblr: UniconComponent
  UilTumblrAlt: UniconComponent
  UilTumblrSquare: UniconComponent
  UilTvRetro: UniconComponent
  UilTvRetroSlash: UniconComponent
  UilTwitter: UniconComponent
  UilTwitterAlt: UniconComponent
  UilUmbrella: UniconComponent
  UilUnamused: UniconComponent
  UilUnderline: UniconComponent
  UilUniversity: UniconComponent
  UilUnlock: UniconComponent
  UilUnlockAlt: UniconComponent
  UilUpload: UniconComponent
  UilUploadAlt: UniconComponent
  UilUsdCircle: UniconComponent
  UilUsdSquare: UniconComponent
  UilUser: UniconComponent
  UilUserArrows: UniconComponent
  UilUserCheck: UniconComponent
  UilUserCircle: UniconComponent
  UilUserExclamation: UniconComponent
  UilUserLocation: UniconComponent
  UilUserMd: UniconComponent
  UilUserMinus: UniconComponent
  UilUserNurse: UniconComponent
  UilUserPlus: UniconComponent
  UilUserSquare: UniconComponent
  UilUserTimes: UniconComponent
  UilUsersAlt: UniconComponent
  UilUtensils: UniconComponent
  UilUtensilsAlt: UniconComponent
  UilVectorSquare: UniconComponent
  UilVectorSquareAlt: UniconComponent
  UilVenus: UniconComponent
  UilVerticalAlignBottom: UniconComponent
  UilVerticalAlignCenter: UniconComponent
  UilVerticalAlignTop: UniconComponent
  UilVerticalDistributeBottom: UniconComponent
  UilVerticalDistributionCenter: UniconComponent
  UilVerticalDistributionTop: UniconComponent
  UilVideo: UniconComponent
  UilVideoQuestion: UniconComponent
  UilVideoSlash: UniconComponent
  UilVirusSlash: UniconComponent
  UilVisualStudio: UniconComponent
  UilVk: UniconComponent
  UilVkAlt: UniconComponent
  UilVoicemail: UniconComponent
  UilVoicemailRectangle: UniconComponent
  UilVolleyball: UniconComponent
  UilVolume: UniconComponent
  UilVolumeDown: UniconComponent
  UilVolumeMute: UniconComponent
  UilVolumeOff: UniconComponent
  UilVolumeUp: UniconComponent
  UilVuejs: UniconComponent
  UilVuejsAlt: UniconComponent
  UilWall: UniconComponent
  UilWallet: UniconComponent
  UilWatch: UniconComponent
  UilWatchAlt: UniconComponent
  UilWater: UniconComponent
  UilWaterDropSlash: UniconComponent
  UilWaterGlass: UniconComponent
  UilWebGrid: UniconComponent
  UilWebGridAlt: UniconComponent
  UilWebSection: UniconComponent
  UilWebSectionAlt: UniconComponent
  UilWebcam: UniconComponent
  UilWeight: UniconComponent
  UilWhatsapp: UniconComponent
  UilWhatsappAlt: UniconComponent
  UilWheelBarrow: UniconComponent
  UilWheelchair: UniconComponent
  UilWheelchairAlt: UniconComponent
  UilWifi: UniconComponent
  UilWifiRouter: UniconComponent
  UilWifiSlash: UniconComponent
  UilWind: UniconComponent
  UilWindMoon: UniconComponent
  UilWindSun: UniconComponent
  UilWindow: UniconComponent
  UilWindowGrid: UniconComponent
  UilWindowMaximize: UniconComponent
  UilWindowSection: UniconComponent
  UilWindows: UniconComponent
  UilWindsock: UniconComponent
  UilWindy: UniconComponent
  UilWordpress: UniconComponent
  UilWordpressSimple: UniconComponent
  UilWrapText: UniconComponent
  UilWrench: UniconComponent
  UilX: UniconComponent
  UilXAdd: UniconComponent
  UilYen: UniconComponent
  UilYenCircle: UniconComponent
  UilYinYang: UniconComponent
  UilYoutube: UniconComponent
} = _Unicons
