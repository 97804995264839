import { CSVDownloaderContext } from '@/stores/contexts/csv-download.context'
import { Box } from '@chakra-ui/react'
import { VFC, ReactNode, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { CSVData } from '../stores/contexts/csv-download.context'

export const CSVDownloaderProvider: VFC<{ children: ReactNode }> = ({
  children,
}) => {
  const csvLinkRef = useRef<
    HTMLAnchorElement & CSVLink & { link: HTMLAnchorElement }
  >(null)

  const [filename, setFilename] = useState<string>('')
  const [data, setData] = useState<CSVData>([])

  return (
    <CSVDownloaderContext.Provider
      value={{
        startDownload: (filename, csvData) => {
          setFilename(filename)
          setData(csvData)

          csvLinkRef.current?.link.click()

          setFilename('')
          setData([])
        },
      }}
    >
      <Box hidden>
        {!!filename && data.length > 0 && (
          <CSVLink filename={filename} data={data} ref={csvLinkRef} />
        )}
      </Box>
      {children}
    </CSVDownloaderContext.Provider>
  )
}
