import { useState } from 'react'

export const useModalAndOverlay = <T extends string>(statuses: T[] = []) => {
  const NONE = 'none' as const

  const [state, setState] = useState<T | typeof NONE>(NONE)

  return {
    isActiveForModalOrOverlay: (name: T) => {
      if (!statuses.includes(name)) {
        return false
      }

      return state === name
    },
    openModalOrOverlay: (name: T) => {
      if (!statuses.includes(name)) {
        setState(NONE)
      }

      setState(name)
    },
    closeModalOrOverlay: () => setState(NONE),
  }
}
