export class AuthenticatedUser {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly role: 'ATINN_USER' | 'CLEANING_USER',
    public readonly cleaningVendorId?: number
  ) {}

  get isAtinnUser() {
    return this.role === 'ATINN_USER'
  }

  get isCleaningUser() {
    return this.role === 'CLEANING_USER'
  }
}
