import { colors } from '@/libs/styles'
import { CircularProgress, CircularProgressProps } from '@chakra-ui/react'
import { VFC } from 'react'

type CircularLoadingProps = Omit<CircularProgressProps, 'isIndeterminate'> & {}

export const CircularLoading: VFC<CircularLoadingProps> = ({
  ...circularProgressProps
}) => {
  return (
    <CircularProgress
      size="24px"
      color={colors.main[1]}
      {...circularProgressProps}
      isIndeterminate
    />
  )
}
