import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { getCleaningVendors } from './__generated__/getCleaningVendors'

export const GET_CLEANING_VENDORS_QUERY = gql`
  query getCleaningVendors {
    cleaningVendors {
      id
      name
      cleaningUsers {
        id
        userId
      }
    }
  }
`

export const useGetCleaningVendorsQuery = (
  options: QueryHookOptions<getCleaningVendors, void> = {}
) => {
  const { data } = useQuery<getCleaningVendors, void>(
    GET_CLEANING_VENDORS_QUERY,
    options
  )

  if (!data?.cleaningVendors) {
    return undefined
  }

  return data.cleaningVendors
}
