import { colors } from '@/libs/styles'
import { Unicons } from '@/libs/icons/unicons'
import { useAuthContext } from '@/stores/contexts/auth.context'
import { Box, VStack } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { VFC } from 'react'
import { LayoutProps } from '../layout.props'
import { BottomMenu } from './parts/bottom-menu'
import { MobileHeader } from './parts/header'
import { BottomMenuItemProps } from './parts/item.bottom-menu'
import { MobileMain } from './parts/main'
import { FloatingMenu } from './parts/floating-menu/floating-menu'
import { useLayoutContext } from '@/stores/contexts/layout.context'

type AuthenticatedMobileLayoutProps = LayoutProps & {}

export const AuthenticatedMobileLayout: VFC<AuthenticatedMobileLayoutProps> = ({
  page,
  children,
}) => {
  const router = useRouter()
  const { logout } = useAuthContext()
  const { menuStatus } = useLayoutContext()

  const bottomMenuItemPropsList: BottomMenuItemProps[] = [
    {
      Icon: Unicons.UilEdit,
      text: '結果入力',
      link: '/cleaning-results',
      type: 'link',
    },
    {
      Icon: Unicons.UilListUiAlt,
      text: '予定管理',
      link: '/cleaning-schedules',
      type: 'link',
    },
    {
      Icon: Unicons.UilSignOutAlt,
      text: 'ログアウト',
      type: 'button',
      onClick: async () => {
        await logout()
        router.push('/login')
      },
    },
  ]
  return (
    <Box h="100vh" w="100vw" backgroundColor={colors.white[3]}>
      <VStack spacing="0" h="100%">
        <Box
          backgroundColor={colors.main[1]}
          h="40px"
          w="100vw"
          position="fixed"
          zIndex={100}
        >
          <MobileHeader title={page.title}></MobileHeader>
        </Box>
        <Box pt="40px" pb="62px" w="100%">
          <MobileMain backgroundColor={colors.white[3]}>{children}</MobileMain>
        </Box>
        <Box
          backgroundColor={colors.main[1]}
          h="62px"
          w="100vw"
          position="fixed"
          bottom="0"
          zIndex={100}
        >
          <BottomMenu
            bottomMenuItemPropsList={bottomMenuItemPropsList}
          ></BottomMenu>
        </Box>
      </VStack>
      {menuStatus && <FloatingMenu status={menuStatus} />}
    </Box>
  )
}
