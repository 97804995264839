import { VFC } from 'react'
import { FloatingMenu } from '@/components/atoms/common/floating-menu'
import { useModalAndOverlay } from '@/hooks/common'
import { BuildingFloatingMenuOverlay } from './building-floating-menu-overlay'
import { BuildingMenuStatusType } from '@/stores/contexts/layout.context'

type BuildingFloatingMenuProps = {
  status: BuildingMenuStatusType
}

export const BuildingFloatingMenu: VFC<BuildingFloatingMenuProps> = ({
  status,
}) => {
  const { closeModalOrOverlay, openModalOrOverlay, isActiveForModalOrOverlay } =
    useModalAndOverlay(['building-floating-menu-overlay'])

  return (
    <>
      <BuildingFloatingMenuOverlay
        isOpen={isActiveForModalOrOverlay('building-floating-menu-overlay')}
        onClose={closeModalOrOverlay}
        status={status}
      />
      <FloatingMenu
        onClick={() => openModalOrOverlay('building-floating-menu-overlay')}
      />
    </>
  )
}
