import { createContext, useContext } from 'react'

type CleaningUser = {
  id: number
  bedMakingEnabled: boolean
  user: {
    id: number
    name: string
    isDisabled: boolean
  }
}

type CleaningVendor = {
  id: number
  name: string
  cleaningUsers: CleaningUser[]
}

type Area = {
  code: number
  name: string
}

type ManagementArea = {
  code: number
  name: string
  areaCode: number | null
  officeLabel: string
  area: Area | null
}

type Room = {
  id: number
  name: string
}

type Building = {
  id: number
  name: string
  buildingManagementAreas: {
    managementArea: Pick<ManagementArea, 'code' | 'name'>
  }[]
  rooms: Room[]
}

export type MasterDataContextType = {
  buildings: Building[]
  cleaningVendors: CleaningVendor[]
  managementAreas: ManagementArea[]
  areas: Area[]
  getCleaningUsers: (
    cleaningVendorId: number | null | undefined
  ) => CleaningUser[]
  getBuilding: (buildingId?: number) => Building | undefined
  getRoom: (roomId?: number) => (Room & {building: Building}) | undefined
}

export const MasterDataContext = createContext<MasterDataContextType>({
  buildings: [],
  cleaningVendors: [],
  managementAreas: [],
  areas: [],
  getCleaningUsers: (cleaningVendorId: number | null | undefined) => [],
  getBuilding: (buildingId) => undefined,
  getRoom: (roomId) => undefined
})

export const useMasterDataContext = () => useContext(MasterDataContext)
