import { useBreakpointValue } from '@chakra-ui/react'
import { useResponsiveStylingObject } from '@/utils/style.util'

export const useIsMobile = ({
  defaultValue = true,
}: {
  defaultValue?: boolean
} = {}) => {
  return (
    useBreakpointValue(
      useResponsiveStylingObject<boolean>({ mobile: true, desktop: false })
    ) ?? defaultValue
  )
}
