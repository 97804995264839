import { authLoginFormInputLocaleTexts } from './input'

export const authLoginFormToastLocaleTexts = {
  success: {
    title: 'ログイン成功',
    description: (username: string) => `${username}としてログインしました。`,
  },
  failure: {
    title: 'ログイン失敗',
    description: `${authLoginFormInputLocaleTexts.username.label}/${authLoginFormInputLocaleTexts.password.label} のどちらかが間違っている可能性があります。`,
  },
}
