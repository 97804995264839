import { MenuStatusType } from '@/stores/contexts/layout.context'
import { VFC } from 'react'
import { BuildingHeadMenu } from './building.head-menu'
import { RoomHeadMenu } from './room.head-menu'

type HeadMenuProps = {
  status: NonNullable<MenuStatusType>
}

export const HeadMenu: VFC<HeadMenuProps> = ({ status }) => {
  if (status.type === 'building') {
    return <BuildingHeadMenu status={status} />
  }

  if (status.type === 'room') {
    return <RoomHeadMenu status={status} />
  }

  return <></>
}
