import { MenuItemCoreProps } from '@/components/layouts/types/core.menu-item-props.type'
import { colors, fontSizes } from '@/libs/styles'
import { VStack } from '@chakra-ui/react'
import { VFC } from 'react'

type BottomMenuItemCoreProps = MenuItemCoreProps

export const BottomMenuItemCore: VFC<BottomMenuItemCoreProps> = ({
  Icon,
  text,
}) => {
  return (
    <VStack
      textColor={colors.white[7]}
      fontSize={fontSizes.tiny}
      fontWeight="bold"
      spacing="4px"
    >
      <div>
        <Icon size={24}></Icon>
      </div>
      <p>{text}</p>
    </VStack>
  )
}
