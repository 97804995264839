import { VFC } from 'react'
import { CognitoUser } from '@aws-amplify/auth'
import { css } from '@emotion/react'
import { Box, HStack } from '@chakra-ui/react'
import { colors, fontSizes } from '@/libs/styles'

type MobileHeader = {
  title: string
}

export const MobileHeader: VFC<MobileHeader> = ({ title }) => {
  return (
    <header
      css={css`
        height: 100%;
        width: 100%;
      `}
    >
      <HStack h="100%" px={4} justifyContent="space-between">
        <HStack>
          <Box
            textColor={colors.white[3]}
            fontSize={fontSizes.small}
            fontWeight="bold"
          >
            {title}
          </Box>
        </HStack>
      </HStack>
    </header>
  )
}
