export const cardInformationLocaleTexts = {
  scheduleDate: {
    title: '清掃予定日',
    defaultValue: '予定を入力して下さい。',
  },
  cleaningStaffItem: {
    title: '清掃担当者',
    defaultValue: '担当者を選択して下さい。',
  },
  contractPeriodItem: {
    title: '契約期間',
    defaultValue: '-',
    periodDivision: {
      notLessThanAMonth: '1ヵ月以上',
      lessThanAMonth: '1ヵ月未満',
    },
    unit: {
      days: '日間',
      endUsers: '名',
    },
  },
  nextMovingInDateItem: {
    title: '次回入居・見学',
    defaultValue: '未定',
  },
  managementAreaItem: {
    title: '営業エリア',
    defaultValue: '-',
  },
  cleaningAreaItem: {
    title: '清掃エリア',
    defaultValue: '-',
  },
  roomItem: {
    title: '物件・部屋',
    defaultValue: '-',
  },
  memoItem: {
    title: '特記事項',
    defaultValue: '',
  },
  addressItem: {
    defaultValue: '-',
  },
  cleaningVendorItem: {
    title: '清掃担当業者',
    defaultValue: '未定',
  },
  bedItem: {
    title: 'ベッド',
    defaultValue: '-',
  },
  businessFormatItem: {
    title: '業態',
    defaultValue: '-',
  },
  evacuateDateItem: {
    title: '退去確認日',
    defaultValue: '-',
  },
}
