export const fontSizes = {
  h1: '4rem',
  h2: '3rem',
  h3: '3rem',
  hero: '4rem',
  h4: '2.25rem',
  h5: '2.25rem',
  h6: '1.5rem',
  text: '1rem',
  small: '0.88rem',
  little: '0.75rem',
  tiny: '0.62rem',
}
