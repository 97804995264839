import { useApolloClient } from '@/libs/graphql/apollo-client'
import { ChakraProvider } from '@chakra-ui/react'
import { ReactNode, VFC } from 'react'
import { ApolloProvider } from './apollo-provider'
import { AuthContextProvider } from './auth-context.provider'
import { CSVDownloaderProvider } from './csv-downloader.provider'
import { LayoutProvider } from './layout.provider'
import { MasterDataProvider } from './master-data-provider'
import { PageContextProvider } from './page-context.provider'

export type ProviderProps = { children: ReactNode }

type AppProviderProps = ProviderProps & {}

export const AppProvider: VFC<AppProviderProps> = ({ children }) => {
  const apolloClient = useApolloClient()
  return (
    <ChakraProvider>
      <ApolloProvider client={apolloClient}>
        <AuthContextProvider>
          <MasterDataProvider>
            <PageContextProvider>
              <CSVDownloaderProvider>
                <LayoutProvider>{children}</LayoutProvider>
              </CSVDownloaderProvider>
            </PageContextProvider>
          </MasterDataProvider>
        </AuthContextProvider>
      </ApolloProvider>
    </ChakraProvider>
  )
}
