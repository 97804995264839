import { colors } from '@/libs/styles'
import { Grid, Text, VStack } from '@chakra-ui/react'
import Link, { LinkProps } from 'next/link'
import { VFC } from 'react'

type TabLinkItemAtomProps = {
  children: string
  href?: LinkProps['href']
  isActive?: boolean
}

export const TabLinkItemAtom: VFC<TabLinkItemAtomProps> = ({
  children,
  href = '',
  isActive = false,
}) => {
  return (
    <Link href={href}>
      <a>
        <VStack
          borderBottomWidth={isActive ? '3px' : '0px'}
          borderBottomColor={isActive ? colors.white[7] : 'inherit'}
          height="48px"
          justifyContent="center"
        >
          <Text textColor={isActive ? colors.white[7] : colors.main[6]}>
            {children}
          </Text>
        </VStack>
      </a>
    </Link>
  )
}
