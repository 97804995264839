import { Nullable } from '@/types/function/nullable'

type SearchQueryByPlaceName = {
  placeName: string
}

type SearchQueryByAddress = {
  address: string
}

type SearchQueryByLatitudeAndLongitude = {
  latitude: number
  longitude: number
}

type SearchQueryByGeneralSearchTerms = {
  generalSearchTerms: string[]
}

type SearchQueryType = (
  | Nullable<SearchQueryByPlaceName>
  | Nullable<SearchQueryByAddress>
  | Nullable<SearchQueryByLatitudeAndLongitude>
  | Nullable<SearchQueryByGeneralSearchTerms>
) & {
  queryPlaceId?: string | null
}

const isSearchQueryByPlaceName = (
  query: SearchQueryType
): query is SearchQueryByPlaceName =>
  'placeName' in query && query.placeName !== null

const isSearchQueryByAddress = (
  query: SearchQueryType
): query is SearchQueryByAddress => 'address' in query && query.address !== null

const isSearchQueryByLatitudeAndLongitude = (
  query: SearchQueryType
): query is SearchQueryByLatitudeAndLongitude =>
  'latitude' in query &&
  'longitude' in query &&
  query.latitude !== null &&
  query.longitude !== null

const isSearchQueryByGeneralSearchTerms = (
  query: SearchQueryType
): query is SearchQueryByGeneralSearchTerms => 'generalSearchTerms' in query

export const useGoogleMapSearchLink = (query: SearchQueryType) => {
  const searchUrlBase = 'https://www.google.com/maps/search/?api=1'

  const queries = [
    searchQueryTypeToQueryString(query),
    searchQueryTypeToQueryPlaceIdString(query),
  ].filter((item: string | undefined): item is string => item !== undefined)

  if (queries.length < 1) {
    return undefined
  }

  return `${searchUrlBase}&${queries.join('&')}`
}

const searchQueryTypeToQueryPlaceIdString = (query: SearchQueryType) => {
  if (query.queryPlaceId === undefined || query.queryPlaceId === null) {
    return undefined
  }

  return `query_place_id=${query.queryPlaceId}`
}

const searchQueryTypeToQueryString = (query: SearchQueryType) => {
  const toQueryString = (query: string) => `query=${encodeURI(query)}`
  if (isSearchQueryByPlaceName(query)) {
    return toQueryString(query.placeName)
  }

  if (isSearchQueryByAddress(query)) {
    return toQueryString(query.address)
  }

  if (isSearchQueryByLatitudeAndLongitude(query)) {
    return toQueryString(`${query.latitude},${query.longitude}`)
  }

  if (isSearchQueryByGeneralSearchTerms(query)) {
    return toQueryString(query.generalSearchTerms.join(' '))
  }

  return undefined
}
