import { VFC } from 'react'
import { css } from '@emotion/react'
import { Box, HStack } from '@chakra-ui/react'
import { colors, fontSizes } from '@/libs/styles'
import { AuthenticatedUser } from '@/classes/authenticated-user'

type DesktopHeader = {
  title: string
  user: AuthenticatedUser | null
}
export const DesktopHeader: VFC<DesktopHeader> = ({ title, user }) => {
  return (
    <header
      css={css`
        height: 100%;
        width: 100%;
      `}
    >
      <HStack h="100%" px={16} justifyContent="space-between">
        <HStack>
          <Box
            textColor={colors.white[7]}
            fontSize={fontSizes.h6}
            fontWeight="bold"
          >
            {title}
          </Box>
        </HStack>
        <HStack>
          <Box
            textColor={colors.white[7]}
            fontSize={fontSizes.small}
            fontWeight="bold"
          >
            {!!user && user.name}
          </Box>
        </HStack>
      </HStack>
    </header>
  )
}
