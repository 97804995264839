import { VFC } from 'react'
import { Box, HStack, VStack } from '@chakra-ui/react'
import { colors } from '@/libs/styles'
import { DesktopHeader } from './parts/header'
import { LayoutProps } from '../layout.props'
import { DesktopMain } from './parts/main'

type UnauthenticatedDesktopLayoutProps = LayoutProps & {}

export const UnauthenticatedDesktopLayout: VFC<
  UnauthenticatedDesktopLayoutProps
> = ({ page, children }) => {
  return (
    <Box h="100vh" backgroundColor={colors.white[3]}>
      <VStack spacing="0" h="100vh">
        <Box
          backgroundColor={colors.main[1]}
          h="64px"
          w="100%"
          position="fixed"
          zIndex={100}
        >
          <DesktopHeader title={page.title} user={null}></DesktopHeader>
        </Box>
        <HStack
          flexDirection="row"
          width="100%"
          height="100%"
          alignItems="flex-start"
          justifyContent="flex-start"
          pt={16}
          spacing="0"
        >
          <Box flexGrow={1} h="100%">
            <DesktopMain py="48px">{children}</DesktopMain>
          </Box>
        </HStack>
      </VStack>
    </Box>
  )
}
