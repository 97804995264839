import { MenuStatusType } from '@/stores/contexts/layout.context'
import { VFC } from 'react'
import { BuildingFloatingMenu } from './building/building-floating-menu'
import { RoomFloatingMenu } from './room/room-floating-menu'

type FloatingMenuProps = { status: NonNullable<MenuStatusType> }

export const FloatingMenu: VFC<FloatingMenuProps> = ({ status }) => {
  if (status.type === 'building') {
    return <BuildingFloatingMenu status={status} />
  }

  if (status.type === 'room') {
    return <RoomFloatingMenu status={status} />
  }

  return <></>
}
