import { ReactNode, VFC } from 'react'
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerContentProps,
  HStack,
  Text,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
} from '@chakra-ui/react'
import { colors, fontSizes } from '@/libs/styles'
import { Unicons } from '@/libs/icons/unicons'

export type OverlayProps = {
  styleProps?: Omit<DrawerContentProps, 'children'>
  isOpen: boolean
  onClose: () => void
  children?: ReactNode
  title?: string
  footerNode?: ReactNode
}

export const Overlay: VFC<OverlayProps> = ({
  onClose,
  isOpen = false,
  styleProps = {},
  children = <></>,
  title = 'オーバーレイ',
  footerNode = <></>,
}) => {
  return (
    <Drawer placement="bottom" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent
        pt="12px"
        pb="32px"
        px="16px"
        borderTopRadius="8px"
        {...styleProps}
      >
        <DrawerHeader w="100%">
          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight="bold" fontSize={fontSizes.text}>
              {title}
            </Text>
            <button onClick={onClose}>
              <Unicons.UilTimes size={36} color={colors.gray[4]} />
            </button>
          </HStack>
        </DrawerHeader>
        {children && <DrawerBody w="100%">{children}</DrawerBody>}
        {footerNode && <DrawerFooter w="100%">{footerNode}</DrawerFooter>}
      </DrawerContent>
    </Drawer>
  )
}
