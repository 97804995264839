import { createContext, useContext } from 'react'

export type CSVData = {
  [key: string]: number | string | Date | null | undefined
}[]

export type CSVDownloaderContextType = {
  startDownload: (filename: string, csvData: CSVData) => void
}

export const CSVDownloaderContext = createContext<CSVDownloaderContextType>({
  startDownload: (filename, csvData) =>
    console.debug('startDownload', filename, csvData),
})

export const useCSVDownloaderContext = () => useContext(CSVDownloaderContext)
