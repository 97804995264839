import { createContext, useContext } from 'react'

export type PageContextType = {
  title: string
  changeTitle: (newTitle: string) => void
}

export const PageContext = createContext<PageContextType>({
  title: '',
  changeTitle: (newTitle) => console.log(newTitle),
})

export const usePageContext = () => useContext(PageContext)
