import { LinkMenuItemProps } from '@/components/layouts/types/link.menu-item-props.type'
import { BoxProps } from '@chakra-ui/react'
import { css } from '@emotion/react'
import Link from 'next/link'
import { VFC } from 'react'
import { SideMenuItemCore } from './core'

export type LinkSideMenuItemProps = BoxProps & LinkMenuItemProps

export const LinkSideMenuItem: VFC<Omit<LinkSideMenuItemProps, 'type'>> = ({
  link,
  ...coreProps
}) => {
  return (
    <Link href={link}>
      <a style={{ width: '100%' }}>
        <SideMenuItemCore {...coreProps}></SideMenuItemCore>
      </a>
    </Link>
  )
}
