import {
  ApolloProviderProps,
  ApolloProvider as ParentApolloProvider,
} from '@apollo/client/react/context'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { VFC } from 'react'
import { Auth } from 'aws-amplify'
import { fromPromise, NormalizedCacheObject, Operation } from '@apollo/client'

const getAuthorizationHeader = async (): Promise<string> => {
  const user = await Auth.currentAuthenticatedUser()

  const now = new Date()
  const exp = new Date(
    user.getSignInUserSession().getIdToken().payload.exp * 1000
  )
  const iat = new Date(
    user.getSignInUserSession().getIdToken().payload.iat * 1000
  )
  const tokenIsExpired = now < iat || exp <= now

  if (!user || tokenIsExpired) {
    return ''
  }

  return `Bearer ${user.getSignInUserSession()?.getIdToken().getJwtToken()}`
}

export const ApolloProvider: VFC<
  Omit<ApolloProviderProps<NormalizedCacheObject>, 'client'> & {
    client: ApolloProviderProps<NormalizedCacheObject>['client'] | null
  }
> = ({ client, children }) => {
  if (client === null) {
    return <></>
  }

  const authLink = setContext(async (_, { headers }) => {
    const authorizationHeader = await getAuthorizationHeader()

    if (!authorizationHeader) {
      return { headers }
    }
    return {
      headers: {
        ...headers,
        authorization: authorizationHeader,
      },
    }
  })

  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      for (let error of graphQLErrors) {
        switch (error.extensions.code) {
          case 'UNAUTHENTICATED': // 認証失敗時 (リフレッシュ直前のリクエストなど)
            const oldHeaders = operation.getContext().headers

            return fromPromise(
              getAuthorizationHeader()
                .then((authorizationHeader) => {
                  if (!authorizationHeader) {
                    return undefined
                  }

                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      authorization: authorizationHeader,
                    },
                  })

                  return operation
                })
                .catch((_) => undefined)
            )
              .filter((value): value is Operation => !!value)
              .flatMap((operation) => {
                return forward(operation)
              })
        }
      }
    }
  })

  client.setLink(errorLink.concat(authLink.concat(client.link)))
  return <ParentApolloProvider client={client}>{children}</ParentApolloProvider>
}
