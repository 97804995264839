import { MenuItemCoreProps } from '@/components/layouts/types/core.menu-item-props.type'
import { colors } from '@/libs/styles'
import { Box, Grid, GridItem, GridProps, HStack, Text } from '@chakra-ui/react'
import { VFC } from 'react'

export type SideMenuItemCoreProps = Omit<
  GridProps,
  'templateRows' | 'templateColumns'
> &
  MenuItemCoreProps

export const SideMenuItemCore: VFC<SideMenuItemCoreProps> = ({
  Icon,
  text,
  ...gridProps
}) => {
  const colRepeatSize = 12
  const iconSize = 3
  const labelSize = colRepeatSize - iconSize
  return (
    <Grid
      templateRows="repeat(1, 1fr)"
      templateColumns={`repeat(${colRepeatSize}, 1fr)`}
      textColor={colors.white[7]}
      fontSize="24px"
      fontWeight="bold"
      px="32px"
      {...gridProps}
    >
      <GridItem colSpan={iconSize}>
        <Icon size={36}></Icon>
      </GridItem>
      <GridItem colSpan={labelSize}>
        <Text textAlign="left">{text}</Text>
      </GridItem>
    </Grid>
  )
}
