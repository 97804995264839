import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { persistCache, LocalForageWrapper } from 'apollo3-cache-persist'
import { useEffect, useState } from 'react'
import localforage from 'localforage'

export const useApolloClient = () => {
  const cache = new InMemoryCache()
  const [client, setClient] =
    useState<ApolloClient<NormalizedCacheObject> | null>(null)

  useEffect(() => {
    localforage.config({
      driver: localforage.INDEXEDDB,
      name: 'cleaning-management-system-cache-persistence',
      version: 1.0,
    })
    persistCache({
      cache,
      maxSize: 52_428_800,
      storage: new LocalForageWrapper(localforage),
    }).then(() => {
      setClient(
        new ApolloClient({
          uri: process.env.NEXT_PUBLIC_GRAPHQL_URI,
          cache: cache,
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'cache-and-network',
              errorPolicy: 'all',
            },
            mutate: {
              errorPolicy: 'none',
            },
          },
        })
      )
    })
  }, [])

  return client
}
