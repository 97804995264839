import { AuthenticatedDesktopLayout } from '@/components/layouts/desktop/authenticated.desktop.layout'
import { UnauthenticatedDesktopLayout } from '@/components/layouts/desktop/unauthenticated.desktop.layout'
import { AuthenticatedMobileLayout } from '@/components/layouts/mobile/authenticated.mobile.layout'
import { UnauthenticatedMobileLayout } from '@/components/layouts/mobile/unauthenticated.mobile.layout'
import { useIsMobile } from '@/hooks/common'
import { useAuthContext } from '@/stores/contexts/auth.context'
import { MenuStatusType, LayoutContext } from '@/stores/contexts/layout.context'
import { usePageContext } from '@/stores/contexts/page.context'
import { ReactNode, useState, VFC } from 'react'

type LayoutProviderProps = {
  children: ReactNode
}

const MobileLayoutProvider: VFC<LayoutProviderProps> = ({ children }) => {
  const authContext = useAuthContext()
  const pageContext = usePageContext()

  if (!authContext.isAuthenticated) {
    return (
      <UnauthenticatedMobileLayout page={{ title: pageContext.title }}>
        {children}
      </UnauthenticatedMobileLayout>
    )
  }

  return (
    <AuthenticatedMobileLayout page={{ title: pageContext.title }}>
      {children}
    </AuthenticatedMobileLayout>
  )
}

const DesktopLayoutProvider: VFC<LayoutProviderProps> = ({ children }) => {
  const authContext = useAuthContext()
  const pageContext = usePageContext()

  if (!authContext.isAuthenticated) {
    return (
      <UnauthenticatedDesktopLayout page={{ title: pageContext.title }}>
        {children}
      </UnauthenticatedDesktopLayout>
    )
  }

  return (
    <AuthenticatedDesktopLayout
      page={{ title: pageContext.title }}
      user={authContext.user}
    >
      {children}
    </AuthenticatedDesktopLayout>
  )
}

export const LayoutProvider: VFC<LayoutProviderProps> = ({ children }) => {
  const isMobile = useIsMobile()

  const [menuStatus, setMenuStatus] = useState<MenuStatusType>(undefined)

  if (isMobile) {
    return (
      <LayoutContext.Provider
        value={{
          menuStatus,
          setMenuStatus,
        }}
      >
        <MobileLayoutProvider>{children}</MobileLayoutProvider>
      </LayoutContext.Provider>
    )
  }

  return (
    <LayoutContext.Provider
      value={{
        menuStatus,
        setMenuStatus,
      }}
    >
      <DesktopLayoutProvider>{children}</DesktopLayoutProvider>
    </LayoutContext.Provider>
  )
}
