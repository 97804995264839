import { VFC } from 'react'
import {
  ButtonBottomMenuItem,
  ButtonBottomMenuItemProps,
} from './bottom-menu-item/button'
import {
  LinkBottomMenuItem,
  LinkBottomMenuItemProps,
} from './bottom-menu-item/link'

export type BottomMenuItemProps =
  | LinkBottomMenuItemProps
  | ButtonBottomMenuItemProps

export const BottomMenuItem: VFC<BottomMenuItemProps> = (props) => {
  switch (props.type) {
    case 'link':
      return <LinkBottomMenuItem {...props} />
    case 'button':
      return <ButtonBottomMenuItem {...props} />
  }
}
