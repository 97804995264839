import { AuthContext, useAuthContext } from '@/stores/contexts/auth.context'
import { ReactNode, useEffect, useState, VFC } from 'react'
import { Auth } from 'aws-amplify'
import { useAuth } from '@/hooks/auth'
import { useRouter } from 'next/router'
import { AuthenticatedUser } from '@/classes/authenticated-user'
import { CircularLoading } from '../components/atoms/common/circular-loading'

type AuthenticatedGuardProps = {
  children: ReactNode
  isLoading: boolean
}
const AuthenticatedGuard: VFC<AuthenticatedGuardProps> = ({
  children,
  isLoading,
}) => {
  const authContext = useAuthContext()
  const router = useRouter()

  const canAccess = (isAuthenticated: boolean, path: string) => {
    const canAccessPathsByAnonymous = ['/login']

    return isAuthenticated || canAccessPathsByAnonymous.includes(path)
  }

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!canAccess(authContext.isAuthenticated, router.route)) {
      const route: {
        pathname: string
        query?: {
          [key: string]: string
        }
      } = { pathname: '/login' }

      if (router.asPath !== '/') {
        route.query = { to: router.asPath }
      }

      router.push(route)
    }
  }, [authContext.isAuthenticated, isLoading])

  return (
    <div>
      {!isLoading &&
        canAccess(authContext.isAuthenticated, router.route) &&
        children}
      {isLoading && (
        <h1>
          <CircularLoading />
          認証情報を取得中
        </h1>
      )}
    </div>
  )
}

export const AuthContextProvider: VFC<{ children: ReactNode }> = ({
  children,
}) => {
  const { user: authUser, isLoading, isAtinnStaff, updateAuth } = useAuth()
  const [user, setUser] = useState<AuthenticatedUser | null>(null)
  const router = useRouter()
  useEffect(() => {
    setUser(authUser)
  }, [authUser])

  const login = async (username: string, password: string) => {
    const user = await Auth.signIn({ username, password })
    updateAuth()
  }

  const logout = async () => {
    await Auth.signOut()
    setUser(null)
    router.push('/login')
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated: user !== null,
        isAtinnStaff,
        login,
        logout,
      }}
    >
      <AuthenticatedGuard isLoading={isLoading}>{children}</AuthenticatedGuard>
    </AuthContext.Provider>
  )
}
