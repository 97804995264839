export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string }) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  "buildings": {
    _buildingId: (buildingId: string | number) => ({
      "equipments": {
        $url: (url?: { hash?: string }) => ({ pathname: '/buildings/[buildingId]/equipments' as const, query: { buildingId }, hash: url?.hash })
      },
      "parkings": {
        $url: (url?: { hash?: string }) => ({ pathname: '/buildings/[buildingId]/parkings' as const, query: { buildingId }, hash: url?.hash })
      },
      "photos": {
        $url: (url?: { hash?: string }) => ({ pathname: '/buildings/[buildingId]/photos' as const, query: { buildingId }, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/buildings/[buildingId]' as const, query: { buildingId }, hash: url?.hash })
    })
  },
  "cleaning_results": {
    $url: (url?: { hash?: string }) => ({ pathname: '/cleaning-results' as const, hash: url?.hash })
  },
  "cleaning_schedules": {
    $url: (url?: { hash?: string }) => ({ pathname: '/cleaning-schedules' as const, hash: url?.hash })
  },
  "login": {
    $url: (url?: { hash?: string }) => ({ pathname: '/login' as const, hash: url?.hash })
  },
  "rooms": {
    _roomId: (roomId: string | number) => ({
      "equipments": {
        $url: (url?: { hash?: string }) => ({ pathname: '/rooms/[roomId]/equipments' as const, query: { roomId }, hash: url?.hash })
      },
      "photos": {
        $url: (url?: { hash?: string }) => ({ pathname: '/rooms/[roomId]/photos' as const, query: { roomId }, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/rooms/[roomId]' as const, query: { roomId }, hash: url?.hash })
    })
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

export type PagesPath = typeof pagesPath

export const staticPath = {
  favicon_ico: '/favicon.ico',
  images: {
    common: {
      no_photo_jpg: '/images/common/no-photo.jpg'
    },
    logos: {
      logo_png: '/images/logos/logo.png'
    }
  },
  vercel_svg: '/vercel.svg'
} as const

export type StaticPath = typeof staticPath
