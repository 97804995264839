import { PageContext } from '@/stores/contexts/page.context'
import { ReactNode, useState, VFC } from 'react'

export const PageContextProvider: VFC<{ children: ReactNode }> = ({
  children,
}) => {
  const [pageTitle, setPageTitle] = useState('')

  return (
    <PageContext.Provider
      value={{
        title: pageTitle,
        changeTitle: (newTitle) => setPageTitle(newTitle),
      }}
    >
      {children}
    </PageContext.Provider>
  )
}
