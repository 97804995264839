import { colors } from '@/libs/styles'
import { UniconComponent } from '@/libs/icons/unicons'
import {
  Button as _Button,
  ButtonProps as _ButtonProps,
  CircularProgress,
  useBreakpointValue,
} from '@chakra-ui/react'
import { VFC } from 'react'
import { useResponsiveStylingObject } from '@/utils/style.util'

export type ButtonProps = Omit<_ButtonProps, 'leftIcon' | 'rightIcon'> & {
  leftIcon?: UniconComponent
  rightIcon?: UniconComponent
  isLoading?: boolean
}

export const Button: VFC<ButtonProps> = ({
  children,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  isDisabled = false,
  isLoading = false,
  ...props
}) => {
  const iconSize =
    useBreakpointValue(
      useResponsiveStylingObject<number>({ mobile: 16, desktop: 24 })
    ) || 16
  const loadingSize =
    useBreakpointValue(
      useResponsiveStylingObject<string>({ mobile: '24px', desktop: '24px' })
    ) || '24px'
  const icons = isLoading
    ? {}
    : {
        leftIcon: LeftIcon && <LeftIcon size={iconSize} />,
        rightIcon: RightIcon && <RightIcon size={iconSize} />,
      }

  let content = children

  if (isLoading) {
    content = (
      <CircularProgress
        isIndeterminate
        size={loadingSize}
        color={colors.main[1]}
      />
    )
  }

  return (
    <_Button
      py={useResponsiveStylingObject<string>({ mobile: '4px', desktop: '6px' })}
      px={useResponsiveStylingObject<string>({
        mobile: '32px',
        desktop: '48px',
      })}
      backgroundColor={colors.white[4]}
      borderStyle="solid"
      borderColor={colors.main[1]}
      boxSizing="border-box"
      color={colors.main[1]}
      fontWeight="bold"
      borderWidth={useResponsiveStylingObject<string>({
        mobile: '2px',
        desktop: '4px',
      })}
      borderRadius={useResponsiveStylingObject<string>({
        mobile: '4px',
        desktop: '8px',
      })}
      fontSize={useResponsiveStylingObject<string>({
        mobile: '14px',
        desktop: '16px',
      })}
      lineHeight={useResponsiveStylingObject<string>({
        mobile: '20px',
        desktop: '23px',
      })}
      iconSpacing={0}
      isDisabled={isDisabled || isLoading}
      _hover={{
        borderColor: colors.main[3],
        color: colors.main[3],
        backgroundColor: colors.white[5],
      }}
      _active={{
        borderColor: colors.main[3],
        color: colors.main[3],
        backgroundColor: colors.white[1],
      }}
      _focus={{
        borderColor: colors.main[1],
        color: colors.main[1],
        backgroundColor: colors.white[1],
      }}
      {...icons}
      {...props}
    >
      {content}
    </_Button>
  )
}
