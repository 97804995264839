import { ReactNode } from 'react'

type ResponsiveStylingObjectType<T extends string | number | ReactNode> = {
  mobile: T
  desktop: T
}

export const useResponsiveStylingObject = <
  T extends string | number | boolean | ReactNode
>({
  mobile,
  desktop,
}: ResponsiveStylingObjectType<T>) => ({
  base: mobile,
  lg: desktop,
})
