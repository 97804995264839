import {
  ApolloQueryResult,
  mergeOptions,
  OperationVariables,
  QueryOptions,
  useApolloClient,
} from '@apollo/client'

export const usePureQuery = <TData = any, TVariables = OperationVariables>(
  query: QueryOptions<TVariables, TData>['query'],
  _options: Omit<QueryOptions<TVariables, TData>, 'query'> = {}
): ((
  options: Omit<QueryOptions<TVariables, TData>, 'query'>
) => Promise<ApolloQueryResult<TData>>) => {
  const apolloClient = useApolloClient()
  const defaultOptions: Omit<QueryOptions<TVariables, TData>, 'query'> = {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  }

  return async (options) =>
    await apolloClient.query(
      mergeOptions<QueryOptions<TVariables, TData>>(
        Object.assign(defaultOptions, _options),
        Object.assign(options, { query })
      )
    )
}
