import { HStack } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { VFC } from 'react'
import { BottomMenuItem, BottomMenuItemProps } from './item.bottom-menu'

type BottomMenuProps = {
  bottomMenuItemPropsList: BottomMenuItemProps[]
}

export const BottomMenu: VFC<BottomMenuProps> = ({
  bottomMenuItemPropsList,
}) => {
  const bottomMenuItems = bottomMenuItemPropsList.map(
    (bottomMenuItemProps, index) => (
      <BottomMenuItem
        key={`bottom-menu-item-${index}`}
        {...bottomMenuItemProps}
      />
    )
  )

  return (
    <footer
      css={css`
        height: 100%;
      `}
    >
      <HStack
        h="100%"
        justifyContent="center"
        alignItems="center"
        spacing="22px"
      >
        {bottomMenuItems}
      </HStack>
    </footer>
  )
}
