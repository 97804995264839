import { AuthenticatedUser } from '@/classes/authenticated-user'
import { Auth } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { useGetCleaningVendorsQuery } from './graphql/query/get-cleaning-vendors.query'

type CognitoUser = {
  attributes: {
    'custom:atinndb__users__id': string
    'custom:role': 'ATINN_USER' | 'CLEANING_USER'
    email: string
    name: string
  }
}

export const useAuth = (): {
  user: AuthenticatedUser | null
  isLoading: boolean
  isAtinnStaff: boolean
  updateAuth: () => Promise<void>
} => {
  const [user, setUser] = useState<AuthenticatedUser | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isAtinnStaff, setIsAtinnStaff] = useState<boolean>(false)
  const cleaningVendors = useGetCleaningVendorsQuery()

  const updateAuth = async () => {
    try {
      const currentUser = (await Auth.currentAuthenticatedUser()) as
        | CognitoUser
        | undefined

      if (!currentUser) {
        return
      }

      if (cleaningVendors === undefined || cleaningVendors.length < 1) {
        return
      }

      const userId = parseInt(
        currentUser.attributes['custom:atinndb__users__id']
      )

      const user = new AuthenticatedUser(
        parseInt(currentUser.attributes['custom:atinndb__users__id']),
        currentUser.attributes.name,
        currentUser.attributes['custom:role'],
        Math.max(
          ...(cleaningVendors || [])
            .filter(
              (cleaningVendor) =>
                !!cleaningVendor.cleaningUsers.some(
                  (cleaningUser) => cleaningUser.userId === userId
                )
            )
            .map((cleaningVendor) => cleaningVendor.id),
          0
        ) || undefined
      )
      setUser(user)
      setIsAtinnStaff(user.isAtinnUser)
      setIsLoading(false)
    } catch {
      setUser(null)
      setIsAtinnStaff(false)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    updateAuth()

    return () => setUser(null)
  }, [JSON.stringify(cleaningVendors)])

  return {
    user,
    isLoading,
    isAtinnStaff,
    updateAuth,
  }
}
