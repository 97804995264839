import { colors } from '@/libs/styles'
import { UniconComponent, Unicons } from '@/libs/icons/unicons'
import { Box } from '@chakra-ui/react'
import { VFC } from 'react'

type FloatingMenuProps = {
  onClick: () => void
  Icon?: UniconComponent
}

export const FloatingMenu: VFC<FloatingMenuProps> = ({
  onClick,
  Icon = Unicons.UilBars,
}) => {
  return (
    <Box
      as="button"
      position="fixed"
      bottom="75px"
      right="24px"
      zIndex={110}
      backgroundColor={colors.main[1]}
      w="46px"
      h="46px"
      borderRadius="50%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      _active={{}}
      _focus={{}}
    >
      <Icon color={colors.white[7]} size={32} />
    </Box>
  )
}
