export const colors = {
  /* Color styles */
  main: {
    1: 'rgba(78, 119, 187, 1)',
    2: 'rgba(100, 136, 195, 1)',
    3: 'rgba(122, 153, 204, 1)',
    4: 'rgba(144, 170, 213, 1)',
    5: 'rgba(166, 187, 221, 1)',
    6: 'rgba(189, 204, 230, 1)',
    7: 'rgba(211, 221, 238, 1)',
  },
  sub: {
    1: 'rgba(173, 60, 50, 1)',
    2: 'rgba(199, 71, 60, 1)',
    3: 'rgba(207, 98, 88, 1)',
    4: 'rgba(215, 124, 116, 1)',
    5: 'rgba(223, 150, 144, 1)',
    6: 'rgba(231, 176, 172, 1)',
    7: 'rgba(239, 203, 199, 1)',
  },
  accent: {
    1: 'rgba(208, 173, 97, 1)',
    2: 'rgba(214, 183, 117, 1)',
    3: 'rgba(220, 194, 136, 1)',
    4: 'rgba(226, 204, 156, 1)',
    5: 'rgba(232, 214, 176, 1)',
    6: 'rgba(237, 224, 196, 1)',
    7: 'rgba(243, 235, 216, 1)',
  },
  white: {
    1: 'rgba(224, 231, 243, 1)',
    2: 'rgba(228, 235, 245, 1)',
    3: 'rgba(233, 238, 247, 1)',
    4: 'rgba(237, 241, 248, 1)',
    5: 'rgba(242, 245, 250, 1)',
    6: 'rgba(246, 248, 252, 1)',
    7: 'rgba(251, 252, 253, 1)',
  },
  black: {
    1: 'rgba(9, 15, 24, 1)',
    2: 'rgba(13, 22, 35, 1)',
    3: 'rgba(17, 29, 46, 1)',
    4: 'rgba(21, 35, 57, 1)',
    5: 'rgba(25, 42, 67, 1)',
    6: 'rgba(29, 49, 78, 1)',
    7: 'rgba(33, 56, 89, 1)',
  },
  gray: {
    1: 'rgba(91, 99, 112, 1)',
    2: 'rgba(108, 118, 133, 1)',
    3: 'rgba(128, 137, 152, 1)',
    4: 'rgba(149, 157, 169, 1)',
    5: 'rgba(170, 176, 186, 1)',
    6: 'rgba(191, 196, 203, 1)',
    7: 'rgba(213, 216, 221, 1)',
  },
  system: {
    ok: 'rgba(78, 161, 187, 1)',
    success: 'rgba(78, 187, 102, 1)',
    warning: 'rgba(221, 123, 52, 1)',
    danger: 'rgba(187, 78, 78, 1)',
    notice: 'rgba(78, 119, 187, 1)',
  },

  /* Text-size styles */
  /* base size: text (16px) */
  // --h1--t-e-x-t: 4rem;
  // --h2--t-e-x-t: 3rem;
  // --h3--t-e-x-t: 3rem;
  // --h-e-r-o--t-e-x-t: 4rem;
  // --h4--text: 2.25rem;
  // --h5--text: 2.25rem;
  // --t6--text: 1.5rem;
  // --bold--text: 1rem;
  // --text: 1rem;
  // --small--bold--text: 0.88rem;
  // --small--text: 0.88rem;
  // --small--thin--text: 0.88rem;
  // --little--bold--text: 0.75rem;
  // --little--text: 0.75rem;
  // --little--thin--text: 0.75rem;
  // --tiny--bold--text: 0.62rem;
  // --tiny--text: 0.62rem;
  // --tiny--thin--text: 0.62rem;

  /* Effect styles */
  // --sp-card-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  // --sp-overlay-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
  // --pc-card-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  // --pc-modal-shadow: 0px 0px 48px rgba(0, 0, 0, 0.25);
  // --sp-card-shadowhover: 0px 0px 4px rgba(0, 0, 0, 0.25);
  // --pc-card-shadow-hover: 0px 0px 8px rgba(0, 0, 0, 0.25);
}
