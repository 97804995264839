import { ButtonMenuItemProps } from '@/components/layouts/types/button.menu-item-props.type'
import { css } from '@emotion/react'
import { VFC } from 'react'
import { SideMenuItemCore } from './core'

export type ButtonSideMenuItemProps = ButtonMenuItemProps

export const ButtonSideMenuItem: VFC<Omit<ButtonSideMenuItemProps, 'type'>> = ({
  onClick,
  ...coreProps
}) => {
  return (
    <button
      css={css`
        width: 100%;
      `}
      onClick={onClick}
    >
      <SideMenuItemCore {...coreProps}></SideMenuItemCore>
    </button>
  )
}
