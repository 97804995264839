const shadowColor = 'rgba(0, 0, 0, 0.25)'

export const boxShadows = {
  none: 'none',
  mobile: {
    card: {
      normal: `0px 0px 4px ${shadowColor}`,
      hover: `0px 0px 4px 3px ${shadowColor}`,
    },
    overlay: {
      normal: `0px 0px 32px ${shadowColor}`,
    },
  },
  desktop: {
    card: {
      normal: `0px 0px 8px ${shadowColor}`,
      hover: `0px 0px 8px 4px ${shadowColor}`,
    },
    modal: {
      normal: `0px 0px 48px ${shadowColor}`,
    },
  },
}
