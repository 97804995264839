import { Box, BoxProps } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { ReactNode, VFC } from 'react'

type MobileMainProps = BoxProps & {
  children: ReactNode
}

export const MobileMain: VFC<MobileMainProps> = ({ children, ...boxProps }) => {
  return (
    <Box pt="32px" pb="72px" minH="100%" {...boxProps}>
      <main
        css={css`
          width: 100%;
        `}
      >
        {children}
      </main>
    </Box>
  )
}
