import {
  MasterDataContext,
  MasterDataContextType,
} from '@/stores/contexts/master-data.context'
import { gql, useQuery } from '@apollo/client'
import { ReactNode, useEffect, useState, VFC } from 'react'
import { getMasterData } from './__generated__/getMasterData'

const GET_MASTER_DATA = gql`
  query getMasterData {
    buildings {
      id
      name
      buildingManagementAreas {
        managementArea {
          code
          name
        }
      }
      rooms {
        id
        name
      }
    }
    cleaningVendors {
      id
      name
      orderNumber
      cleaningUsers {
        id
        bedMakingEnabled
        user {
          id
          name
          isDisabled
        }
      }
    }
    managementAreas {
      code
      name
      areaCode
      officeLabel
      area {
        code
        name
      }
    }
    areas {
      code
      name
    }
  }
`

const buildingDataFormatter = (
  buildingData: getMasterData['buildings'][number]
) => ({
  id: buildingData.id,
  name: buildingData.name || '',
  buildingManagementAreas: buildingData.buildingManagementAreas.map((item) => ({
    managementArea: {
      code:
        (item.managementArea?.code && parseInt(item.managementArea?.code)) || 0,
      name: item.managementArea?.name || '',
    },
  })),
  rooms: buildingData.rooms.map((room) => ({
    id: room.id,
    name: room.name || '',
  })),
})

const roomDataFormatter = (
  roomData: getMasterData['buildings'][number]['rooms'][number]
) => ({
  id: roomData.id,
  name: roomData.name || '',
})

export const MasterDataProvider: VFC<{ children: ReactNode }> = ({
  children,
}) => {
  const { data } = useQuery<getMasterData>(GET_MASTER_DATA)
  const [value, setValue] = useState<MasterDataContextType>({
    buildings: [],
    cleaningVendors: [],
    managementAreas: [],
    areas: [],
    getCleaningUsers: () => [],
    getBuilding: () => undefined,
    getRoom: () => undefined,
  })

  useEffect(() => {
    if (!data) {
      return
    }

    setValue({
      buildings:
        data?.buildings?.map((item) => buildingDataFormatter(item)) || [],
      cleaningVendors:
        data?.cleaningVendors
          .slice()
          .sort((left, right) =>
            left.orderNumber < right.orderNumber ? -1 : 1
          )
          .map((item) => ({
            id: item.id,
            name: item.name,
            cleaningUsers: item.cleaningUsers
              .slice()
              .sort((left, right) =>
                left.user.isDisabled < right.user.isDisabled ? -1 : 1
              )
              .map((user) => ({
                id: user.id,
                bedMakingEnabled: user.bedMakingEnabled,
                user: {
                  id: user.user.id,
                  name: user.user.name || '',
                  isDisabled: user.user.isDisabled,
                },
              })),
          })) || [],
      managementAreas:
        data?.managementAreas?.map((item) => ({
          code: (item.code && parseInt(item.code)) || 0,
          name: item.name || '',
          areaCode: item.areaCode,
          officeLabel: item.officeLabel,
          area: {
            code: (item.area?.code && parseInt(item.area.code)) || 99,
            name: item.area?.name || 'その他',
          },
        })) || [],
      areas:
        data?.areas.map((item) => ({
          code: (item.code && parseInt(item.code)) || 99,
          name: item.name || 'その他',
        })) || [],
      getCleaningUsers: (cleaningVendorId: number | null | undefined) => {
        return (
          data?.cleaningVendors
            ?.filter((item) => item.id === cleaningVendorId)[0]
            ?.cleaningUsers.slice()
            .sort((left, right) =>
              left.user.isDisabled < right.user.isDisabled ? -1 : 1
            )
            .map((item) => ({
              id: item.id,
              bedMakingEnabled: item.bedMakingEnabled,
              user: {
                id: item.user.id,
                name: item.user.name || '',
                isDisabled: item.user.isDisabled,
              },
            })) || []
        )
      },
      getBuilding: (buildingId: number | undefined) => {
        const buildingData = data?.buildings.filter(
          (building) => building.id === buildingId
        )[0]

        if (buildingData === undefined) {
          return undefined
        }

        return buildingDataFormatter(buildingData)
      },
      getRoom: (roomId: number | undefined) => {
        const buildingData = data?.buildings.find(
          (building) =>
            building.rooms.find((room) => room.id === roomId) !== undefined
        )

        if (buildingData === undefined) {
          return undefined
        }

        const roomData = buildingData.rooms.find((room) => room.id === roomId)

        if (roomData === undefined) {
          return undefined
        }

        return {
          ...roomDataFormatter(roomData),
          building: buildingDataFormatter(buildingData),
        }
      },
    })
  }, [JSON.stringify(data)])
  return (
    <MasterDataContext.Provider value={value}>
      {children}
    </MasterDataContext.Provider>
  )
}
