import { colors } from '@/libs/styles'
import { Box, HStack, VStack } from '@chakra-ui/react'
import { VFC } from 'react'
import { LayoutProps } from '../layout.props'
import { MobileHeader } from './parts/header'
import { MobileMain } from './parts/main'

type UnauthenticatedMobileLayoutProps = LayoutProps & {}

export const UnauthenticatedMobileLayout: VFC<
  UnauthenticatedMobileLayoutProps
> = ({ page, children }) => {
  return (
    <Box h="100vh" backgroundColor={colors.white[3]}>
      <VStack spacing="0" h="100%">
        <Box
          backgroundColor={colors.main[1]}
          h="40px"
          w="100%"
          position="fixed"
          zIndex={100}
        >
          <MobileHeader title={page.title}></MobileHeader>
        </Box>
        <Box pt="40px" w="100%">
          <MobileMain>{children}</MobileMain>
        </Box>
      </VStack>
    </Box>
  )
}
