import { colors } from '@/libs/styles'
import { Unicons } from '@/libs/icons/unicons'
import { useAuthContext } from '@/stores/contexts/auth.context'
import { Box, HStack, VStack } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { VFC } from 'react'
import { DesktopHeader } from './parts/header'
import { LayoutProps } from '../layout.props'
import { DesktopMain } from './parts/main'
import { SideMenuItemProps } from './parts/item.side-menu'
import { DesktopSideMenu } from './parts/side-menu'
import { AuthenticatedUser } from '@/classes/authenticated-user'
import { useLayoutContext } from '@/stores/contexts/layout.context'
import { HeadMenu } from './parts/head-menu/head-menu'

export type AuthenticatedDesktopLayoutProps = LayoutProps & {
  user: AuthenticatedUser | null
}

export const AuthenticatedDesktopLayout: VFC<AuthenticatedDesktopLayoutProps> =
  ({ page, user, children }) => {
    const { logout } = useAuthContext()
    const router = useRouter()

    const sideMenuItemPropsList: SideMenuItemProps[] = [
      {
        Icon: Unicons.UilListUiAlt,
        text: '予定管理',
        link: '/cleaning-schedules',
        type: 'link',
      },
      {
        Icon: Unicons.UilEdit,
        text: '結果入力',
        link: '/cleaning-results',
        type: 'link',
      },
      {
        Icon: Unicons.UilSignOutAlt,
        text: 'ログアウト',
        type: 'button',
        onClick: async () => {
          await logout()
          router.push('/login')
        },
      },
    ]

    const { menuStatus: headMenuStatus } = useLayoutContext()

    return (
      <Box h="100vh">
        <VStack spacing="0" h="100vh">
          <Box
            backgroundColor={colors.main[1]}
            h="64px"
            w="100%"
            position="fixed"
            zIndex={100}
          >
            <DesktopHeader title={page.title} user={user} />
          </Box>
          <HStack
            flexDirection="row"
            width="100%"
            height="100%"
            alignItems="flex-start"
            justifyContent="flex-start"
            pt={16}
            spacing="0"
          >
            <Box
              backgroundColor={colors.main[2]}
              w={64}
              height="100%"
              position="fixed"
              pt="32px"
              zIndex={100}
            >
              <DesktopSideMenu sideMenuItemPropsList={sideMenuItemPropsList} />
            </Box>
            <Box pl={64} flexGrow={1} h="100%">
              {headMenuStatus && (
                <Box
                  backgroundColor={colors.main[1]}
                  width="calc(100% - 256px)"
                  flexGrow={1}
                  position="fixed"
                  zIndex={1}
                >
                  <HeadMenu status={headMenuStatus} />
                </Box>
              )}
              <DesktopMain
                pb="48px"
                pt={headMenuStatus ? '96px' : '48px'}
                backgroundColor={colors.white[3]}
              >
                {children}
              </DesktopMain>
            </Box>
          </HStack>
        </VStack>
      </Box>
    )
  }
