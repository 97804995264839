import { ObjectSchema } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

type UseYupValidationResolverProps<TCast, TContext, TOutput> = {
  validationSchema: ObjectSchema<any, any, any>
}

export const useYupValidationResolver = <TCast, TContext, TOutput>({
  validationSchema,
}: UseYupValidationResolverProps<TCast, TContext, TOutput>) =>
  yupResolver(validationSchema)
