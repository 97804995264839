import { format } from 'date-fns'

export const dateLocaleTexts = {
  fullDate: (date: number | Date) => format(date, 'y年MM月dd日'),
  periodDivision: {
    notLessThanAMonth: '1ヵ月以上',
    lessThanAMonth: '1ヵ月未満',
    default: '-',
  },
}
