export const authLoginFormInputLocaleTexts = {
  username: {
    label: 'ログインID',
    placeholder: 'abc123def456',
  },
  password: {
    label: 'パスワード',
    placeholder: '********',
  },
}
