import { createContext, useContext } from 'react'
import { AuthenticatedUser } from '@/classes/authenticated-user'

export type AuthContextType = {
  user: AuthenticatedUser | null
  isAuthenticated: boolean
  isAtinnStaff: boolean
  login: (username: string, password: string) => Promise<void>
  logout: () => Promise<void>
}
export const AuthContext = createContext<AuthContextType>({
  user: null,
  isAuthenticated: false,
  isAtinnStaff: false,
  login: async (username: string, password: string) =>
    console.log('ログイン', username, password),
  logout: async () => console.log('ログアウト'),
})

export const useAuthContext = () => useContext(AuthContext)
