import { VFC } from 'react'
import { FloatingMenu } from '@/components/atoms/common/floating-menu'
import { useModalAndOverlay } from '@/hooks/common'
import { RoomMenuStatusType } from '@/stores/contexts/layout.context'
import { RoomFloatingMenuOverlay } from './room-floating-menu-overlay'

type RoomFloatingMenuProps = {
  status: RoomMenuStatusType
}

export const RoomFloatingMenu: VFC<RoomFloatingMenuProps> = ({ status }) => {
  const { closeModalOrOverlay, openModalOrOverlay, isActiveForModalOrOverlay } =
    useModalAndOverlay(['room-floating-menu-overlay'])

  return (
    <>
      <RoomFloatingMenuOverlay
        isOpen={isActiveForModalOrOverlay('room-floating-menu-overlay')}
        onClose={closeModalOrOverlay}
        status={status}
      />
      <FloatingMenu
        onClick={() => openModalOrOverlay('room-floating-menu-overlay')}
      />
    </>
  )
}
