import { Overlay, OverlayProps } from '@/components/atoms/common/overlay'
import { VStack } from '@chakra-ui/react'
import Link, { LinkProps } from 'next/link'
import { VFC } from 'react'
import { Button, ButtonProps } from '@/components/atoms/forms/button.atom'
import { BuildingMenuStatusType } from '@/stores/contexts/layout.context'
import { pagesPath } from '@/utils/$path'

type BuildingFloatingMenuOverlayProps = Omit<
  OverlayProps,
  'children' | 'title'
> & {
  status: BuildingMenuStatusType
}

type LinkButtonProps = ButtonProps & {
  href: LinkProps['href']
}

const LinkButton: VFC<LinkButtonProps> = ({ href, ...buttonProps }) => {
  return (
    <Link href={href}>
      <a>
        <Button {...buttonProps} />
      </a>
    </Link>
  )
}

export const BuildingFloatingMenuOverlay: VFC<BuildingFloatingMenuOverlayProps> =
  ({ status, ...overlayProps }) => {
    const buildingPages = pagesPath.buildings._buildingId(
      status.variables.buildingId
    )
    const links: {
      label: string
      href: LinkProps['href']
      name: BuildingMenuStatusType['activeTabName']
    }[] = [
      { label: '建物情報', href: buildingPages.$url(), name: 'detail' },
      {
        label: '駐車場',
        href: buildingPages.parkings.$url(),
        name: 'parking',
      },
      {
        label: '物件設備',
        href: buildingPages.equipments.$url(),
        name: 'equipment',
      },
      {
        label: '物件写真',
        href: buildingPages.photos.$url(),
        name: 'photo',
      },
    ]

    return (
      <Overlay title="物件情報メニュー" {...overlayProps}>
        <VStack>
          {links.map((link) => (
            <LinkButton
              key={`building-floating-menu--${link.name}`}
              href={link.href}
              onClick={overlayProps.onClose}
              disabled={link.name === status.activeTabName}
            >
              {link.label}
            </LinkButton>
          ))}
        </VStack>
      </Overlay>
    )
  }
