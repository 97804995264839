import { Overlay, OverlayProps } from '@/components/atoms/common/overlay'
import { VStack } from '@chakra-ui/react'
import Link, { LinkProps } from 'next/link'
import { VFC } from 'react'
import { Button, ButtonProps } from '@/components/atoms/forms/button.atom'
import { RoomMenuStatusType } from '@/stores/contexts/layout.context'
import { pagesPath } from '@/utils/$path'

type RoomFloatingMenuOverlayProps = Omit<OverlayProps, 'children' | 'title'> & {
  status: RoomMenuStatusType
}

type LinkButtonProps = ButtonProps & {
  href: LinkProps['href']
}

const LinkButton: VFC<LinkButtonProps> = ({ href, ...buttonProps }) => {
  return (
    <Link href={href}>
      <a>
        <Button {...buttonProps} />
      </a>
    </Link>
  )
}

export const RoomFloatingMenuOverlay: VFC<RoomFloatingMenuOverlayProps> = ({
  status,
  ...overlayProps
}) => {
  const roomPages = pagesPath.rooms._roomId(status.variables.roomId)
  const links: {
    label: string
    href: LinkProps['href']
    name: RoomMenuStatusType['activeTabName']
  }[] = [
    { label: '部屋情報', href: roomPages.$url(), name: 'detail' },
    {
      label: '部屋設備',
      href: roomPages.equipments.$url(),
      name: 'equipment',
    },
    {
      label: '部屋写真',
      href: roomPages.photos.$url(),
      name: 'photo',
    },
  ]

  return (
    <Overlay title="部屋情報メニュー" {...overlayProps}>
      <VStack>
        {links.map((link) => (
          <LinkButton
            key={`room-floating-menu--${link.name}`}
            href={link.href}
            onClick={overlayProps.onClose}
            disabled={link.name === status.activeTabName}
          >
            {link.label}
          </LinkButton>
        ))}
      </VStack>
    </Overlay>
  )
}
