import { BoxProps } from '@chakra-ui/react'
import { VFC } from 'react'
import {
  ButtonSideMenuItem,
  ButtonSideMenuItemProps,
} from './side-menu-item/button'
import { LinkSideMenuItem, LinkSideMenuItemProps } from './side-menu-item/link'

export type SideMenuItemProps = LinkSideMenuItemProps | ButtonSideMenuItemProps

export const SideMenuItem: VFC<SideMenuItemProps> = (props) => {
  switch (props.type) {
    case 'link':
      return <LinkSideMenuItem {...props}></LinkSideMenuItem>
    case 'button':
      return <ButtonSideMenuItem {...props}></ButtonSideMenuItem>
  }
}
