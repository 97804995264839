import { LinkMenuItemProps } from '@/components/layouts/types/link.menu-item-props.type'
import Link from 'next/link'
import { VFC } from 'react'
import { BottomMenuItemCore } from './core'

export type LinkBottomMenuItemProps = LinkMenuItemProps

export const LinkBottomMenuItem: VFC<Omit<LinkBottomMenuItemProps, 'type'>> = ({
  link,
  ...coreProps
}) => {
  return (
    <Link href={link}>
      <a>
        <BottomMenuItemCore {...coreProps}></BottomMenuItemCore>
      </a>
    </Link>
  )
}
