type UseBase64FileDataArgs = {
  base64FileData?: string | null
  mimeType?: string | null
}

type UseBase64FileDataReturns = UseBase64FileDataArgs & {
  base64FileURL: string
}

export const useBase64FileData = (
  args: UseBase64FileDataArgs
): UseBase64FileDataReturns => {
  return {
    ...args,
    base64FileURL:
      (args.mimeType &&
        args.base64FileData &&
        `data:${args.mimeType};base64,${args.base64FileData}`) ||
      '',
  }
}
